// import React from 'react';
import ISOAxios, { DataPackage } from "../apis/ISOAxios";
import useSessionStorage from "./useSessionStorage";
import useBase64 from "./useBase64";
import {
  doChangePassword,
  doUpdateProfile,
} from "src/framework/core/handlers/SystemAction";

/**
 * 로그인 사용자 정보 확인 및 수정
 * 비밀번호 변경
 */
export default function useUserInfo() {
  const { loadSessionStorage } = useSessionStorage("loginSign");
  const { base64Encode } = useBase64();
  const signinUser = loadSessionStorage();

  /**
   * 사용자정보를 수정한다.
   * @param {*} Values - Json Object
   * @param {*} Callback (result, error)
   */
  const updateProfile = async function (Values) {
    if (
      Values === undefined ||
      Values === null ||
      JSON.stringify(Values) === "{}"
    )
      return null;

    try {
      const response = await doUpdateProfile(
        signinUser.PLT_ID,
        signinUser.USER_ID,
        Values
      );
      return response;
    } catch (e) {
      return e;
    }
  };

  /**
   * BIZ Procedure PROC_CHANGE_PASSWORD.
   * let resultData = JSON.parse(ret.JsonData)[0];
   * Column - RESULT (resultData.RESULT)
   * 301 - 이전 비밀번호 오류
   * 200 - 정상
   * @param {*} oldPass 이전 비밀번호
   * @param {*} newPass 새로운 비밀번호
   * @param {*} Callback (result, error)
   */
  const changePassword = async (oldPass, newPass) => {
    try {
      const response = await doChangePassword(
        signinUser.PLT_ID,
        signinUser.USER_ID,
        oldPass,
        newPass
      );
      return response;
    } catch (e) {
      return e;
    }
  };

  return {
    signinUser,
    updateProfile,
    changePassword,
  };
}
