import React from 'react';
import TableCell from '@material-ui/core/TableCell';

class ISOTableGridCell extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <TableCell
          {...this.props}
          onClick={this.props.onClick}
          component={this.props.columnIndex === 0 ? 'th' : null}
          scope={this.props.columnIndex === 0 ? 'row' : null}
          onMouseDown={this.props.onMouseDown}
        />
      </>
    );
  }
}

export default ISOTableGridCell;
