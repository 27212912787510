const tableGridApi = {
  RowState: {
    Detached: "Detached",
    Unchanged: "Unchanged",
    Added: "Added",
    Deleted: "Deleted",
    Modified: "Modified",
    Total: "Total",
    SubTotal: "SubTotal",
  },
  SelectionMode: {
    Row: "Row",
    Cell: "Cell",
  },
  addColumnGroup: ({ label, align, name }) => {
    const group = {};
    group.label = label;
    group.align = align;
    group.name = name;
    return group;
  },
  addColumn: ({
    label,
    dataField,
    width = 50,
    allowEdit = false,
    hAlign = "center",
    vAlign = "middle",
    visible = true,
    columnGroup,
    template,
    editor,
    dataType,
    formatFunction,
    allowResize = true,
    cellRenderer,
  }) => {
    const column = {};
    column.align = hAlign;
    column.label = label;
    column.dataField = dataField;
    column.width = width;
    column.allowEdit = allowEdit;
    column.cellsAlign = hAlign; // TODO: 필요없는지 확인하여 삭제
    column.cellsVerticalAlign = vAlign;
    column.verticalAlign = "middle";
    column.allowResize = allowResize;
    column.visible = visible;
    column.cellRenderer = cellRenderer;

    if (template) {
      column.template = template;
    }

    if (editor) {
      column.editor = editor;
    }

    if (columnGroup) {
      column.columnGroup = columnGroup;
    }

    if (formatFunction) {
      column.formatFunction = formatFunction;
    }

    if (dataType) {
      column.dataType = dataType;
    }

    return column;
  },
};

export { tableGridApi };
