import React from "react";
import PropsType from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import { e3 } from "../../core/utils/utils";

registerLocale("ko", ko);

class ISODateTime extends React.PureComponent {
  datePicker;

  constructor(props) {
    super(props);
    this.datePicker = React.createRef(DatePicker);

    this.state = {
      value: new Date(),
      minDate: props.minDate,
      maxDate: props.maxDate,
      readOnly: props.readOnly,
      disabled: props.disabled,
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  clear = () => {
    this.setState({
      value: "",
      minDate: "",
      maxDate: "",
      startDate: "",
      endDate: "",
    });
  };

  enable = () => {
    this.setState({
      disabled: false,
    });
  };

  disable = () => {
    this.setState({
      disabled: true,
    });
  };

  setDate = (source) => {
    this.setState({
      value: e3.string.stringToDate(source, this.props.dateFormat),
    });
  };

  getDate = (defaultFormat = true) => {
    if (defaultFormat && typeof defaultFormat === "boolean") {
      return e3.date.dateToString(this.state.value, this.props.dateFormat);
    }
    if (defaultFormat && typeof defaultFormat === "string") {
      return e3.date.dateToString(this.state.value, defaultFormat);
    }

    return this.state.value;
  };

  getValue = () => {
    return this.state.value;
  };

  getDate2String = (fmt) => {
    if (!this.state.value.valueOf()) return "";
    const d = this.state.value;
    const weekName = [
      "일요일",
      "월요일",
      "화요일",
      "수요일",
      "목요일",
      "금요일",
      "토요일",
    ];
    let format = fmt === undefined ? this.props.dateFormat : fmt;
    return format.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, ($1) => {
      switch ($1) {
        case "yyyy":
          return d.getFullYear();
        case "yy":
          return e3.string.lPad(d.getFullYear() % 1000, 2);
        case "MM":
          return e3.string.lPad(d.getMonth() + 1, 2);
        case "dd":
          return e3.string.lPad(d.getDate(), 2);
        case "E":
          return weekName[d.getDay()];
        case "HH":
          return e3.string.lPad(d.getHours(), 2);
        case "hh":
          return e3.string.lPad(d.getHours() % 12 ? d.getHours() % 12 : 12, 2);
        case "mm":
          return e3.string.lPad(d.getMinutes(), 2);
        case "ss":
          return e3.string.lPad(d.getSeconds(), 2);
        case "a/p":
          return d.getHours() < 12 ? "오전" : "오후";
        default:
          return $1;
      }
    });
  };

  toLocaleDateString = (locale) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let lo = locale === undefined ? locale : undefined;
    return this.state.value.toLocaleDateString(lo, options);
  };

  getUTCDate = () => this.state.value.getUTCDate();

  toJSON = () => this.state.value.toJSON();

  setMinDate = (source) => {
    this.setState({
      minDate: e3.string.stringToDate(source, this.props.dateFormat),
    });
  };

  setMaxDate = (source) => {
    this.setState({
      maxDate: e3.string.stringToDate(source, this.props.dateFormat),
    });
  };

  setReadOnly = (source) => {
    this.setState({
      readOnly: e3.string.stringToDate(source, this.props.dateFormat),
    });
  };

  setStartDate = (source) => {
    this.setState({
      startDate: e3.string.stringToDate(source, this.props.dateFormat),
    });
  };

  setEndDate = (source) => {
    this.setState({
      endDate: e3.string.stringToDate(source, this.props.dateFormat),
    });
  };

  /**
   * show datepirkcer
   */
  onOpen = (e) => {
    this.datePicker.current.setOpen(true);
  };

  onClose = (e) => {
    // this.datePicker.current.setOpen(false);
  };

  onChange = (e) => {
    if (e) this.setDate(e);

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  control = (other) => (
    <DatePicker
      {...other}
      ref={this.datePicker}
      selected={this.state.value}
      disabled={this.state.disabled}
      minDate={this.state.minDate}
      maxDate={this.state.maxDate}
      startDate={this.state.startDate}
      endDate={this.state.endDate}
      locale="ko"
      onChange={this.onChange}
    >
      {/* <div> */}
      {/*  <div>오늘</div> */}
      {/*  <div>닫기</div> */}
      {/* </div> */}
    </DatePicker>
  );

  render() {
    const { textLabel, ...other } = this.props;

    return (
      <>
        {this.props.textLabel ? (
          <>
            <th
              style={{
                width: `${this.props.labelWidth}`,
              }}
            >
              <label className={this.props.required ? "required" : ""}>
                {this.props.textLabel ? this.props.textLabel : ""}
              </label>
            </th>
            <td colSpan={this.props.colspan ? this.props.colspan : ""}>
              {this.control(other)}
            </td>
          </>
        ) : this.props.colspan ? (
          <>
            <td colSpan={this.props.colspan}>{this.control(other)}</td>
          </>
        ) : (
          this.control(other)
        )}
      </>
    );
  }
}

ISODateTime.propTypes = {
  textLabel: PropsType.string,
  colspan: PropsType.number,
  dateFormat: PropsType.string,
  disabled: PropsType.bool,
  minDate: PropsType.object,
  maxDate: PropsType.object,
  selectsRange: PropsType.bool,
  monthsShown: PropsType.number, // 달력 표시
  showPreviousMonths: PropsType.bool, // 금일 기준 이전달 표시
  selectsStart: PropsType.bool,
  startDate: PropsType.string,
  selectsEnd: PropsType.bool,
  endDate: PropsType.string,
  width: PropsType.oneOfType([PropsType.string, PropsType.number]),
  height: PropsType.oneOfType([PropsType.string, PropsType.number]),
  labelWidth: PropsType.string,
};

ISODateTime.defaultProps = {
  width: "100%",
  height: "100%",
  dateFormat: "yyyy-MM-dd",
  disabled: false,
  labelWidth: "120px",
};

export default ISODateTime;
