import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Check from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import useUserInfo from '../core/hooks/useUserInfo';
import { ModalNoButton, ConfirmDialog } from '../components/controls/ISODialog';
import { show } from '../core/reducers/MessageReducer';
import ISOInput from '../components/controls/ISOInput';
import ISOButton from '../components/controls/ISOButton';

const passwordSchema = Yup.object({
  OLD_PASS: Yup.string()
    .required('이전 비밀번호는 필수 입력값 입니다.'),
  NEW_PASS: Yup.string()
    .min(9, '최소 9자리까지 입니다.')
    .max(32, '최대 32자리까지 입니다.')
    .required('신규 비밀번호는 필수 입력값 입니다.')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,32}$/, '하나 이상의 대문자, 소문자, 숫자, 특수 문자의 조합이 필요합니다.(9~32)')
    .when('OLD_PASS', {
      is: (val) => ((val && val.length) > 0),
      then: Yup.string().notOneOf(
        [Yup.ref('OLD_PASS')],
        '이전 비밀번호와 동일합니다.',
      ),
    }),
  NEW_PASS_CONFIRM: Yup.string().when('NEW_PASS', {
    is: (val) => ((val && val.length) > 0),
    then: Yup.string().oneOf(
      [Yup.ref('NEW_PASS')],
      '비밀번호 확인이 일치하지 않습니다.',
    ),
  }),
});

const ChangePassword = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
    isLoading: false,
  });
  const { changePassword } = useUserInfo();
  const dispatcher = useDispatch();
  const showMessgaeInfo = (data) => dispatcher(show(data));
  const intput1 = React.useRef(ISOInput);
  const intput2 = React.useRef(ISOInput);
  const intput3 = React.useRef(ISOInput);
  const changePasswordRef = React.useRef(ISOButton);
  const confirmRef = React.useRef();

  const validationSchema = () => {
    const v1 = intput1.current.getValue();
    const v2 = intput2.current.getValue();
    const v3 = intput3.current.getValue();
    try {

      let passwordRules=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,32}$/;
      if (!passwordRules.test(v2)) {
        throw('하나 이상의 대문자, 소문자, 숫자, 특수 문자의 조합이 필요합니다.(9~32)');
      }
      if (v2 !== v3) {
        throw('비밀번호 변경 값이 일치하지 않습니다.');
      }
      if (v1===v2) {
        throw('이전 비밀번호와 동일합니다.');
      }
      
      // const check = passwordSchema.validateSync(
      //   { OLD_PASS: v1, NEW_PASS: v2, NEW_PASS_CONFIRM: v3 },
      //   {
      //     strict: false,
      //     abortEarly: false,
      //   },
      // );
      return true;
    } catch (e) {
      console.log(e);
      let msg = null;
      if (typeof e === 'string') msg = e.replaceAll('ValidationError: ', '');
      else msg = e.message;
      showMessgaeInfo({
        title: '비밀번호 변경 실패',
        message: msg,
        width: 450,
        height: 200,
        buttonAlign: 'center',
        isShow: true,
        type: 'Normal',
      });
      return false;
    }
  };
  const onModalClose = () => {
    setIsOpen(false);
  };
  const changeHandle = async () => {
    const oldPass = intput1.current.getValue();
    const newPass = intput2.current.getValue();
    const ret = await changePassword(oldPass, newPass);
    if (ret && ret.JsonData && JSON.stringify(ret.JsonData) !== '[]') {
      const chk = JSON.parse(ret.JsonData)[0];
      if (chk.RESULT === 200) {
        onModalClose();
        showMessgaeInfo({
          title: '비밀번호 변경 성공',
          message: '비밀번호를 변경하였습니다.!',
          width: 400,
          height: 200,
          buttonAlign: 'center',
          isShow: true,
          type: 'Normal',
        });
      } else {
        showMessgaeInfo({
          title: '비밀번호 변경 실패',
          message: '비밀번호를 변경을 실패하였습니다.!',
          width: 400,
          height: 200,
          buttonAlign: 'center',
          isShow: true,
          type: 'Normal',
        });
      }
      setValues({ ...values, isLoading: false });
      changePasswordRef.current.enable();
    }
  };
  const onChangePassword = () => {
    if (validationSchema() === true) {
      confirmRef.current.showFunctionDialog(
        '비밀번호를 변경하시겠습니까?',
        () => {
          confirmRef.current.hideDialog();
          setValues({ ...values, isLoading: true });
          changePasswordRef.current.disable();
          changeHandle();
        },
      );
    }
  };
  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };
  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };
  const handleClickShowPassword3 = () => {
    setValues({ ...values, showPassword3: !values.showPassword3 });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault(); // 화면 갱신 방지
  };

  React.useImperativeHandle(
    ref,
    () => ({
      showChangePassword() {
        setIsOpen(true);
      },
    }),
  );

  return (
    <>
      <ModalNoButton
        title="비밀번호 변경"
        isOpen={isOpen}
        dialogId="modal-nobutton-change-password"
        onModalClose={onModalClose}
        width={500}
        height={280}
      >
        <div className="grid-container">
          <div style={{ padding: '5px, 0!important' }}>
            <ISOInput
              placeholder={'기존 비밀번호'}
              type={values.showPassword1 ? 'text' : 'password'}
              ref={intput1}
              required={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
          </div>
          <div style={{ padding: '5px, 0!important' }}>
            <ISOInput
              placeholder={'신규 비밀번호'}
              type={values.showPassword2 ? 'text' : 'password'}
              ref={intput2}
              required={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
          </div>
          <div style={{ padding: '5px, 0!important' }}>
            <ISOInput
              placeholder={'신규 비밀번호 확인'}
              type={values.showPassword3 ? 'text' : 'password'}
              ref={intput3}
              required={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword3}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword3 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
          </div>
          <span className="footnote">※ 허용 특수문자 : &quot;!&quot;, &quot;@&quot;, &quot;#&quot;, &quot;$&quot;, &quot;%&quot;, &quot;&&quot;, &quot;*&quot;, &quot;?&quot;</span>

          <div className="btn_wrap">
            <ISOButton type="button" size="small" ref={changePasswordRef} onClick={onChangePassword} color="primary">
              { values.isLoading ? <CircularProgress color="secondary" size={20} thickness={10} /> : <Check /> }
              비밀번호 변경
            </ISOButton>
            <ISOButton type="button" size="small" onClick={onModalClose}> 닫기 </ISOButton>
          </div>
        </div>
      </ModalNoButton>
      <ConfirmDialog ref={confirmRef} />
    </>
  );
});

export default ChangePassword;
