import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import * as ISO from "../LayerComponents";
// import '../../../styles/DefaultLayout.css';
import "../../../styles/condtrolStyle.css";
import MenuTop from "./MenuTop";
import MenuSide from "./MenuSide";
import Copyrights from "./Copyrights";
import { addTab } from "../../core/reducers/TabReducer";
import TabList from "../../components/tab/TabItemList";
import { ModalOkOnly } from "../../components/controls/ISODialog";
import { hide } from "../../core/reducers/MessageReducer";
import { StorageLoad } from "../../core/hooks/useLocalStorage";
import { alertType, e3 } from "../../core/utils/utils";

const DefaultLayout = React.memo(() => {
  //#region resize event
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  //#endregion

  const messgaeInfo = useSelector((state) => state.message);
  const tabInfo = useSelector((state) => state.tabs);

  const dispatch = useDispatch();
  const onCreate = (payload) => dispatch(addTab(payload));
  const hideMessgaeInfo = () => dispatch(hide());
  const menuList =
    useSelector((state) => state.menuList).dataList ||
    JSON.parse(StorageLoad("MENU"));
  const topMenuList = _.filter(menuList, { LEVEL: 1 });
  // console.log(topMenuList);
  const [menuState, setMenuState] = useState({
    collapsed: false, // 사이드 메뉴 숨김/표시 값
    menuTop: topMenuList.length > 0 ? topMenuList[0].MENU_ID : "", // 탑메뉴 선택 값
    menuSide: "", // 사이드메뉴 선택 값
    sideTabMenuSelected: "sideTabMenu20", // 사이드메뉴 상단 탭 선택 값
    sideMenuAutoHidden: false, // 사이드메뉴 자동 숨김 설정 값
    locale: "ko", // 다국어 선택 값
    siderTimer: null,
    fullScreenMode: false,
  });
  const toggle = async () => {
    await setMenuState({
      ...menuState,
      collapsed: !menuState.collapsed,
    });
  };
  const handleMenuTopClick = async (e) => {
    if (menuState.sideMenuAutoHidden) {
      const oSiderTimer = window.setTimeout(() => {
        hideSideMenu();
      }, 5000);
      await setMenuState({
        ...menuState,
        menuTop: e.target.attributes.data.value,
        sideTabMenuSelected: "sideTabMenu20",
        collapsed: false,
        siderTimer: oSiderTimer,
      });
    } else {
      await setMenuState({
        ...menuState,
        menuTop: e.target.attributes.data.value,
        sideTabMenuSelected: "sideTabMenu20",
      });
    }
  };
  const OSiderOver = (e) => {
    if (menuState.sideMenuAutoHidden) {
      window.clearTimeout(menuState.siderTimer);
    }
  };
  const OSiderOut = (e) => {
    if (menuState.sideMenuAutoHidden) {
      window.setTimeout(() => {
        hideSideMenu();
      }, 700);
    }
  };
  const handleMenuClick = (e, v) => {
    let chkMyData = null;
    if (
      tabInfo.tabList.length ===
      Number.parseInt(process.env.REACT_APP_TAB_COUNT)
    ) {
      e3.modal.alert(
        alertType.Info,
        "화면은 15개까지만 열릴 수 있습니다. 화면을 닫고 다시 시도해주세요."
      );
      return false;
    }
    // if (e && e.item && e.item.props && e.item.props.data) {
    //   onCreate(e.item.props.data);
    // } else if (e && e.target && e.target.dataset && e.target.dataset.myData) {
    //   const myData = findMenu(e.target.dataset.myData);
    //   if (myData?.length > 0) chkMyData = myData;
    //   if (myData && myData.length > 0) onCreate(myData[0]);
    // }
    if (v) {
      const myData = findMenu(v);
      if (myData?.length > 0) chkMyData = myData;
      if (myData && myData.length > 0) onCreate(myData[0]);
    }
    if (menuState.sideMenuAutoHidden && chkMyData?.length > 0) {
      setMenuState({
        ...menuState,
        menuSide: chkMyData[0].MENU_ID,
        collapsed: true,
      });
    } else if (menuState.sideMenuAutoHidden && chkMyData === null) {
      setMenuState({
        ...menuState,
        collapsed: true,
      });
    } else if (!menuState.sideMenuAutoHidden && chkMyData?.length > 0) {
      setMenuState({
        ...menuState,
        menuSide: chkMyData[0].MENU_ID,
      });
    }
  };
  const hideSideMenu = () => {
    if (menuState.sideMenuAutoHidden) {
      setMenuState({
        ...menuState,
        collapsed: true,
        siderTimer: null,
      });
    }
  };
  const debugState = () => {
    console.log("DefaultLayout", menuState);
  };
  const changeLanguage = (e) => {
    setMenuState({
      ...menuState,
      locale: e.target.value,
    });
  };
  const findMenu = (menuid) =>
    menuList.filter((item) => item.MENU_ID === menuid);
  const onCloseMessgaeInfo = () => {
    hideMessgaeInfo();
  };

  useEffect(() => {
    if (topMenuList && topMenuList[0]) {
      setMenuState({
        ...menuState,
        menuTop: topMenuList[0].MENU_ID,
      });
    }
  }, [menuList]);

  return (
    <div
      style={{ width: `${dimensions.width}`, height: `${dimensions.height}` }}
    >
      <ISO.OLayer className="out-layout-content">
        <ISO.OHeader className="main-top">
          <div className="logo">
            <Link to="/">
              <span
                className={
                  menuState.collapsed
                    ? "icon-menu-unfold trigger"
                    : "icon-menu-fold trigger"
                }
                onClick={toggle}
              ></span>
              <img
                style={{ height: "55px", width: "215px", objectFit: "cover" }}
                src="logo.gif"
                alt=""
              />
              {/* <span className="text">출하 물류관리 스마트 시스템</span> */}
            </Link>
          </div>
          <MenuTop
            items={topMenuList}
            handleClick={handleMenuTopClick}
            values={menuState}
            setMenuState={setMenuState}
            changeLanguage={changeLanguage}
          />
        </ISO.OHeader>
        <ISO.OLayer className="layout-sider main-body">
          <ISO.OSider
            collapsible
            collapsedWidth={0}
            collapsed={menuState.collapsed}
            OSiderOver={OSiderOver}
            OSiderOut={OSiderOut}
          >
            <MenuSide
              values={menuState}
              items={menuList}
              setMenuState={setMenuState}
              handleClick={handleMenuClick}
            />
          </ISO.OSider>
          <ISO.OMain>
            <ISO.OLayer>
              <TabList hideSideMenuClick={hideSideMenu} />
            </ISO.OLayer>
          </ISO.OMain>
        </ISO.OLayer>
        <Copyrights />
      </ISO.OLayer>
      <ModalOkOnly
        dialogId="globalMessageDialog"
        title={messgaeInfo.title}
        width={messgaeInfo.width}
        height={messgaeInfo.height}
        isOpen={messgaeInfo.isShow}
        buttonAlign={messgaeInfo.buttonAlign}
        buttonOkTitle="확인"
        onModalOkClick={onCloseMessgaeInfo}
      >
        <div>{messgaeInfo.message}</div>
      </ModalOkOnly>
    </div>
  );
});

export default DefaultLayout;
