import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import JqxSplitter from "jqwidgets-scripts/jqwidgets-react-tsx/jqxsplitter";
import DetailForm from "./DetailForm";

export { PopupArea, PopupInputArea, PopupSearchArea, PopupGridArea } from "./PopupLayout";

export { PageArea, PageSearchArea, AutoHeightArea, PageInputArea } from "./PageLayout";

/**
 * <Button as={number > 1 ? Link : 'button'} disabled={number === 1} href={number > 1 ? `/${number - 1}` : undefined}> <PreviousIcon /> Previous </Button>
 */
export const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: 2px solid deepskyblue;
  border-radius: 8px;
  color: white;
  display: inline-flex;
  font-family: sans-serif;
  font-size: inherit;
  line-height: 1.4rem;
  margin: 0 4px;
  padding: 8px 12px 8px 4px;
  position: relative;
  text-decoration: none;
  transition:
    border-color 150ms ease-out,
    transform 150ms ease-out;

  &:not([disabled]) {
    cursor: pointer;
  }
  &:not([disabled]):hover {
    border-color: white;
  }
  &:not([disabled]):active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
  &:focus::after {
    content: ' ';
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border: 2px solid white;    
    border-radius: 5px;
  }
  &[disabled] {
    opacity: 0.5;
  }
}`;

/**
 * 검색 영역안의 박스 출력
 */
export const SearchBox = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 7px 7px;
    border: 1px solid #bbb;
    border-radius: 2px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
`;
/**
 * 박스 출력이 없는 영역 설정
 */
export const ResultBoxNoBorder = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    height: ${(props) => props.height || "98%"};
    padding: 0;
    margin: 0 auto;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
`;
export const DivPaddingLeft = styled.div`
    display: inline-block;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: ${(props) => props.paddingLeft || "10px"};
`;
DivPaddingLeft.defaultProps = {
    className: "chcMnq",
};
export const DivPaddingRight = styled.div`
    display: inline-block;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height || "100%"};
    margin: 0 auto;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: ${(props) => props.paddingRight || "10px"};
`;
DivPaddingRight.defaultProps = {
    className: "chcMnq",
};
/**
 * Flex group style
 */
export const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};
/**
 * 배지 출력 스타일
 */
export const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
};

export const formatGroupLabel = React.memo((data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
));

/**
 * Result Div Resize
 * @param {*} props {autoHeight, addLayer}
 */
export function AutoHeightCalculate(props) {
    let resultHeight = 500;
    const paddingSize = 4;
    try {
        if (props.autoHeight !== undefined && props.autoHeight) {
            // const contentsHeight = window.getComputedStyle(document.querySelector('.main-body-content')).height;
            const contentsHeight = document.querySelector(".tab-content.active .main-body-content")
                ? document.querySelector(".tab-content.active .main-body-content").clientHeight
                : 754;
            const searchBoxHeight = document.querySelector(".tab-content.active .main-body-search-area")
                ? document.querySelector(".tab-content.active .main-body-search-area").clientHeight
                : 0;
            const formBoxHeight = document.querySelector(".tab-content.active .main-body-form-area")
                ? document.querySelector(".tab-content.active .main-body-form-area").clientHeight
                : 0;
            if (props.addLayer !== undefined && props.addLayer) {
                const addLayerHeight = document.querySelector(`.tab-content.active .${props.addLayer}`)
                    ? document.querySelector(`.tab-content.active .${props.addLayer}`).clientHeight
                    : 0;
                resultHeight =
                    parseInt(contentsHeight) -
                    parseInt(searchBoxHeight) -
                    parseInt(addLayerHeight) -
                    parseInt(formBoxHeight) -
                    paddingSize;
            } else {
                resultHeight =
                    parseInt(contentsHeight) -
                    parseInt(searchBoxHeight) -
                    parseInt(formBoxHeight) -
                    paddingSize;
            }
            // console.log("AutoHeightCalculate :: ", contentsHeight, searchBoxHeight, formBoxHeight, resultHeight);
            if (!resultHeight) {
                document.querySelector(".tab-content.active .main-body-result-area").style.height = "100%";
            } else {
                document.querySelector(
                    ".tab-content.active .main-body-result-area"
                ).style.height = `${resultHeight}px`;
                document.querySelector(
                    ".tab-content.active .main-body-result-area .main-body-result-box"
                ).style.height = `${resultHeight - paddingSize}px`;
                document.querySelector(".tab-content.active .main-body-result-area").style.overflowY = "auto";
            }
        }
    } catch (error) {
        //
    }
}
/**
 * section Tag Layout
 * 영역을 나누는 태그 설정
 * @param {*} props
 */
export const OLayer = React.memo((props) => {
    const cssClass = `layout ${
        props && props.className && props.className !== undefined ? props.className : ""
    }`;
    const htmlId = `${props && props.id && props.id !== undefined ? props.id : ""}`;
    return (
        <section id={htmlId} className={cssClass}>
            {props.children}
        </section>
    );
});

/**
 * Top Menu area layout
 * 탑메뉴 영역의 설정
 */
export const OHeader = React.memo((props) => {
    const cssClass = `layout-header ${
        props && props.className && props.className !== undefined ? props.className : ""
    }`;
    return (
        <header className={cssClass} style={{ padding: "0px" }}>
            {props.children}
        </header>
    );
});

/** *
 * Side menu area layout (Hide & Show - props.collapsed)
 * 좌측 메뉴 출력 영역 설정 (숨김과 보임 기능 설정)
 */
export const OSider = React.memo((props) => {
    const cssClass = `side-menu layout-sider${
        props && props.className && props.className !== undefined ? props.className : ""
    }`;
    const cssWidth = props.collapsedWidth;
    const { collapsible, OSiderOver, OSiderOut } = props;
    const collapsed = collapsible ? props.collapsed : collapsible;
    const cssHideStyle = {
        flex: `0 0 ${cssWidth}px`,
        maxWidth: `${cssWidth}0px`,
        minWidth: `${cssWidth}0px`,
        width: `${cssWidth}0px`,
    };
    const cssShowStyle = {};
    return (
        <aside
            className={cssClass}
            style={collapsed ? cssHideStyle : cssShowStyle}
            onMouseEnter={OSiderOver}
            onMouseLeave={OSiderOut}
        >
            <div className="side-menu-children">{props.children}</div>
        </aside>
    );
});

OSider.propTypes = {
    className: PropTypes.string,
    collapsedWidth: PropTypes.number,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
};
OSider.defaultProps = {
    collapsedWidth: 0,
    collapsible: true,
};

/**
 * Contents Area - O Tabs Here
 * 각 페이지의 내용을 출력하는 영역 (Tabs에서 처리)
 */
export const OMain = React.memo((props) => {
    const cssClass = `layout-content ${
        props && props.className && props.className !== undefined ? props.className : ""
    }`;
    return <main className={cssClass}>{props.children}</main>;
});

/**
 * Title bar in Main Contents Area
 * 페이지 제목 및 브레드크럼 출력하는 영역
 */
export const OTitleBox = React.memo((props) => (
    <div className="main-body-title-area">
        <div className="main-body-title-box">
            <ul className="breadcrumbs-title">
                {props.pageInfo.pageTitle.map((item) => (
                    <li className="breadcrumbs-title-item">{item}</li>
                ))}
            </ul>
            <span>{props.children}</span>
        </div>
    </div>
));

/**
 * Search Object contents here in Main Contents Area
 * 검색 항목에 사용하는 영역
 */
export const OSearchBox = React.memo((props) => (
    <div className="main-body-search-area">
        <div className="main-body-search-box">{props.children}</div>
    </div>
));

/**
 * Search result data to Grid display area in Main Contents Area
 * 검색 결과를 출력하는 그리드를 출력하는 영역
 */
export const OResultBox = React.memo((props) => {
    React.useEffect(() => {
        AutoHeightCalculate(props);
    }, []);

    return (
        <div className="main-body-result-area" style={{ ...props.style }}>
            <div className="main-body-result-box">{props.children}</div>
        </div>
    );
});

/**
 * Form Object - data handle object
 * 데이타의 등록, 수정, 삭제 하는 영역
 */
export const OFormBox = React.memo((props) =>
    props.style !== undefined ? (
        <div className="main-body-form-area" style={props.style}>
            <div className="main-body-form-box">{props.children}</div>
        </div>
    ) : (
        <div className="main-body-form-area">
            <div className="main-body-form-box">{props.children}</div>
        </div>
    )
);

/**
 * Loading page
 * 페이지 열리는 동안 출력되는 영역
 */
export const LoadingPage = React.memo(() => (
    <div className="main-body-lading">
        <div className="main-body-lading-box">Page Loading.......</div>
    </div>
));

/**
 * Grid No data
 * 자료 없음을 출력
 */
export const EmptyRowsRenderer = React.memo(() => (
    <div style={{ textAlign: "center" }}>
        Nothing to show{" "}
        <span lang="ko" title="자료가 존재하지 않습니다.">
            No - Data
        </span>
    </div>
));

/// 그리드 컬럼 24 분활
export const layoutlabel = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

/**
 * 버튼 콘트롤을 Flex하게 출력하기 위한 콤포넌트
 * 버튼을 children 으로 추가한다.
 */
export const ButtonArea = React.memo((props) => (
    <div className="button-control-box" style={{ textAlign: props.align ? props.align : null }}>
        {props.children}
    </div>
));

export const ButtonWrap = React.memo((props) => <div className="btn_wrap">{props.children}</div>);

export const InputWrap = React.memo((props) => (
    <div className="input_record">
        <table>
            <tbody>{props.children}</tbody>
        </table>
    </div>
));

export const SearchWrap = React.memo((props) => (
    <div className="input_search">
        <table>
            <tbody>{props.children}</tbody>
        </table>
    </div>
));

export const RowWrap = React.memo((props) => <tr>{props.children}</tr>);

export const ColumnWrap = React.memo((props) => (
    <>
        {props.textlabel ? (
            <>
                <th>
                    <label>{props.textlabel}</label>
                </th>
                {props.children ? <td>{props.children}</td> : ""}
            </>
        ) : (
            <td>{props.children}</td>
        )}
    </>
));

/**
 * Input 과 Label 을 하나로 사용하는 콤포넌트
 * value 와 onChange 기능을 사용
 */
export const InputTextWithLabel = React.memo((props) => {
    const { onChange } = props;
    return (
        <>
            <div className="inputGroup">
                <label className="formLabel" htmlFor={props.id}>
                    <span> {props.labelText} </span>
                </label>
                <input
                    className="formInput"
                    type="text"
                    id={props.id}
                    value={props.value ? props.value : ""}
                    onChange={onChange}
                />
            </div>
        </>
    );
});
export const InputTextWithLabelRef = React.forwardRef((props, ref) => {
    const [value, setValue] = React.useState("");
    const [disabled, setDisabled] = React.useState(false);
    React.useImperativeHandle(ref, () => ({
        setDisabled(b) {
            setDisabled(b);
        },
        onChange(e) {
            setValue(e);
        },
        async getStateValue() {
            return value;
        },
    }));
    return (
        <>
            <InputTextWithLabel
                {...props}
                value={value}
                disabled={disabled}
                onChange={ref.current.onChange}
            />
        </>
    );
});

/**
 * inputRef를 사용하는 콤포넌트
 * 부모 페이지의 변수와 연결하여 사용
 * inputRef={el=>(inputElement.VOC_NM=el)} - inputElement.VOC_NM 부모 페이지의 변수
 * inputElement 변수 선언은 함수 밖에서 선언하여야 한다.
 * 그렇지 않을 경우 Rerender에서 다시 초기화 되는 문제가 발생한다.
 */
export const InputTextWithLabel4inputRef = React.memo((props) => (
    <>
        <div className="inputGroup">
            <label className="formLabel" htmlFor={props.id}>
                <span> {props.labelText} </span>
            </label>
            <input className="formInput" type="text" id={props.id} ref={props.inputRef} />
        </div>
    </>
));

export const TableBox = React.memo((props) => {
    return (
        <table>
            <tr>{props.children}</tr>
        </table>
    );
});

export const SplitterLayout = React.memo((props) => {
    return (
        <JqxSplitter
            // @ts-ignore
            width={props.width}
            height={props.height}
            resizable={false}
            className={"splitter-layout"}
            panels={[
                { size: props.panel1Size, collapsible: false },
                {
                    size: props.panel2Size,
                    collapsible: false,
                },
            ]}
            orientation={props.orientation}
        >
            {props.children}
        </JqxSplitter>
    );
});

SplitterLayout.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    orientation: PropTypes.string,
    panel1Size: PropTypes.string,
    panel2Size: PropTypes.string,
};
SplitterLayout.defaultProps = {
    orientation: "horizontal",
    panel1Size: "0",
    panel2Size: "0",
    width: "100%",
    height: "100%",
};

export const SplitterPanel = React.memo((props) => {
    return <div className={"splitter-panel"}>{props.children}</div>;
});

export { DetailForm };
