import axios from "axios";
import base64 from "base-64";
import { alertType, e3 } from "../utils/utils";
import { loadCookie } from "../hooks/useCookieData";
import * as CryptoJS from "crypto-js";
import ISOUtils from "../utils/ISOUtils";
import { encryptAES, decryptAES } from "src/framework/core/utils/utils";

const API_URL = process.env.REACT_APP_PROXY; // 'http://localhost'

const axiosRequestConfig = {
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  responseType: "json",
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
};

const axiosInstance = axios.create(axiosRequestConfig);

export const DataPackage = {
  init: () => {
    let dataPackage = {};
    dataPackage.Token = "";
    dataPackage.JsonData = "";
    dataPackage.ErrorMessage = "";
    dataPackage.ProgramId = "";
    dataPackage.MethodName = "";
    dataPackage.Division = "";
    dataPackage.KeyValues = [];
    return dataPackage;
  },
  InitSystemDataPackage: () => {
    let dataPackage = DataPackage.init();
    dataPackage.AssemblyName = "E3.COM.SYS.BIZ.dll";
    dataPackage.Namespace = "E3.COM.SYS.BIZ";
    dataPackage.ClassName = "SYSTEM";
    return dataPackage;
  },
  create: (programid, methodname) => {
    let dataPackage = DataPackage.init();
    dataPackage.ProgramId = programid;
    dataPackage.MethodName = methodname;
    return dataPackage;
  },
  NewKeyValue: (key, value) => {
    let keyValue = {};
    keyValue.Key = key;
    keyValue.Value = value;
    return keyValue;
  },
  GetParam4Plant: () => {
    let m = {};
    m.Key = "PLT_ID";
    m.Value = DataPackage.GetPlantId();
    return m;
  },
  GetPlantId: () => {
    const tmp = window.sessionStorage.getItem("loginSign");
    const ret =
      tmp && tmp.length > 0
        ? JSON.parse(DataPackage.base64Decode(tmp.toString()))
        : undefined;
    return ret.PLT_ID || "";
  },
  base64Decode: (data) => base64.decode(data),
  base64Encode: (data) => base64.encode(data),
};

const ISOAxios = {
  SetToken: (value) => {
    window.sessionStorage.setItem("Token", value);
  },
  GetToken: () => {
    const tmp = window.sessionStorage.getItem("Token");
    const ret = tmp && tmp.length > 0 ? tmp.toString() : undefined;
    return ret;
  },
  post: (params, url) => {},
  Execute: async (params, callback, showLoading = true) => {
    try {
      if (showLoading) {
        e3.ui.showLoading();
      }

      params.Token = ISOAxios.GetToken();

      const jsonData = JSON.stringify(params);
      const encryptData = await encryptAES(jsonData);

      let transferPackage = {};
      transferPackage.EncryptData = encryptData;

      let result = await axiosInstance.post(
        "/execute",
        JSON.stringify(transferPackage)
      );

      if (typeof result.data === "string") {
        result.data = JSON.parse(result.data);
      }

      let resultPackage = await decryptAES(result.data.EncryptData);

      if (typeof resultPackage === "string") {
        resultPackage = JSON.parse(resultPackage);
      }

      if (showLoading) {
        e3.ui.hideLoading();
      }

      // Token 유효성 오류
      const valid = resultPackage.KeyValues.find(
        (item) => item.Key === "ERRORCODE"
      );

      if (valid && valid.Value === "401") {
        e3.modal.alert(
          alertType.Error,
          "시스템 미사용으로 로그아웃 합니다.\n창을닫고 다시 로그인 하여 주세요.",
          () => {
            window.sessionStorage.removeItem("Token");
            window.sessionStorage.removeItem("loginSign");
            window.document.location.reload();
          }
        );
        return false;
      } else if (resultPackage.ErrorMessage) {
        e3.modal.alert(alertType.Error, resultPackage.ErrorMessage);
        return false;
      } else {
        if (callback) {
          callback(resultPackage);
        }
        return resultPackage;
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      if (showLoading) {
        e3.ui.hideLoading();
      }
    }
  },
  Login: async (params, callback = null) => {
    try {
      e3.ui.showLoading();

      const jsonData = JSON.stringify(params);
      const encryptData = await encryptAES(jsonData);

      let transferPackage = {};
      transferPackage.EncryptData = encryptData;

      let result = await axiosInstance.post(
        "/login",
        JSON.stringify(transferPackage)
      );

      if (typeof result.data === "string") {
        result.data = JSON.parse(result.data);
      }

      let resultPackage = await decryptAES(result.data.EncryptData);

      if (typeof resultPackage === "string") {
        resultPackage = JSON.parse(resultPackage);
      }

      e3.ui.hideLoading();

      if (resultPackage.ErrorMessage) {
        throw new Error(resultPackage.ErrorMessage);
      } else {
        ISOAxios.SetToken(resultPackage.Token);

        if (callback) {
          callback(resultPackage);
        }
        return resultPackage;
      }
    } catch (err) {
      e3.ui.hideLoading();
      throw err;
    }
  },
  GetPlantId: () => {
    const tmp = window.sessionStorage.getItem("loginSign");
    const ret =
      tmp && tmp.length > 0
        ? JSON.parse(ISOAxios.base64Decode(tmp.toString()))
        : undefined;
    return ret.PLT_ID || "";
  },
  base64Decode: (data) => base64.decode(data),
  base64Encode: (data) => base64.encode(data),
};

export const GetCodes = async (plantId = "", codeID, temp01 = "") => {
  let params = DataPackage.create("SYS100", "getCodes");
  params.KeyValues.push(DataPackage.NewKeyValue("PLANT_ID", plantId));
  params.KeyValues.push(DataPackage.NewKeyValue("CODE_ID", codeID));
  params.KeyValues.push(DataPackage.NewKeyValue("temp01", temp01));
  const result = await ISOAxios.Execute(params);
  if (result) return JSON.parse(result.JsonData);
  return null;
};

export default ISOAxios;
