// import React from 'react';
import React, { useEffect, useState, useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { MessageOnlyDialog } from "../components/controls/ISODialog";
import ISOInput from "../components/controls/ISOInput";
import ISOCheckBox from "../components/controls/ISOCheckBox";
import ISOButton from "../components/controls/ISOButton";
import useSignIn from "../core/hooks/useSignIn";
import { Link, withRouter } from "react-router-dom";
import { alertType, e3, validationType } from "src/framework/core/utils/utils";
import HANWHA_COM_EMAIL_COLLECTION from "src/pages/HANWHA/HANWHA_COM_EMAIL_COLLECTION";
import HANWHA_COM_SECURITY_DECLARATION from "src/pages/HANWHA/HANWHA_COM_SECURITY_DECLARATION";
import HANWHA_COM_PRIVACY_POLICY from "src/pages/HANWHA/HANWHA_COM_PRIVACY_POLICY";

/**
 * /pages/index 하위 페이지 로그인 페이지 항목을 변경할 경우
 */
export default withRouter(function LoginForm(props) {
  // 로그인 및 메뉴 정보 처리
  const { SignIn, SignInBySSO, loadLoginInfo } = useSignIn();
  const [loginType, setLoginType] = useState(e3.LoginType.SSO);

  const title = `${process.env.REACT_APP_COMPANY_NAME} ${process.env.REACT_APP_PROJECT_NAME} Login`; // 로그인 페이지 제목

  // INIT EVENT
  useEffect(async () => {
    await checkSSO();
    return () => {};
  }, []);

  const checkSSO = () => {
    const slo_emp = window.localStorage.getItem("SLO_EMP");

    if (slo_emp) {
      window.localStorage.removeItem("SLO_EMP");
      setValues({ ...values, isLoading: true });
      SignInBySSO(slo_emp, (response, error) => {
        setValues({ ...values, isLoading: false });
        if (error) {
          location.href = "/";
        }
      });
    } else {
      setLoginType(e3.LoginType.User);
    }
  };

  const { userid, userpw, remember } = loadLoginInfo();
  const [values, setValues] = React.useState({
    showPassword: false,
    isLoading: false,
  });
  const userIdRef = React.useRef(ISOInput);
  const passwordRef = React.useRef(ISOInput);
  const rememberCheckRef = React.useRef(ISOCheckBox);
  const buttonRef = React.useRef(ISOButton);
  const msgRef = React.useRef();

  const privacyPop = useRef(); //개인정보처리방침 팝업
  const emailPop = useRef(); //이메일무단수집거부 팝업
  const securityPop = useRef(); //보안신고 팝업

  const handleValidation = () => {
    if (!userIdRef.current.getValue()) {
      const msg = "사용자 계정 정보를 입력하세요!";
      msgRef.current.showDialog(msg);
      return false;
    }
    if (!passwordRef.current.getValue()) {
      const msg = "비밀번호를 입력하세요!";
      msgRef.current.showDialog(msg);
      return false;
    }
    return true;
  };

  const closeDialog = () => {
    msgRef.current.hideDialog();
  };

  const onFinish = async () => {
    if (handleValidation()) {
      setValues({ ...values, isLoading: true });
      buttonRef.current.disable();
      SignIn(
        userIdRef.current.getValue(),
        passwordRef.current.getValue(),
        loginType,
        (ret, err) => {
          try {
            if (err) {
              if (typeof err === "string") {
                msgRef.current.showDialog(err);
              } else {
                msgRef.current.showDialog(err.message);
              }
              setValues({ ...values, isLoading: false });
            }
            if (ret && ret.ErrorMessage && ret.ErrorMessage.length > 0) {
              msgRef.current.showDialog(ret.ErrorMessage);
            }
            buttonRef.current?.enable();
          } catch (e) {
            console.log(e);
          }
        },
        rememberCheckRef.current.getValue()
      );
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  //개인정보처리방침 클릭
  const onPrivacyClick = () => {
    privacyPop.current.showDialog();
  };

  //이메일무단수집거부 클릭
  const onEmailClick = () => {
    emailPop.current.showDialog();
  };

  //보안신고 클릭
  const onSecurityClick = () => {
    securityPop.current.showDialog();
  };

  return (
    <>
      <CssBaseline />
      <header className="layout-header main-top">
        <div className="logo">
          <Link to="/">
            <img style={{ height: "55px", width: "215px", objectFit: "cover" }} src="logo.gif" alt="" />
            {/*  <span className="text">출하 물류관리 스마트 시스템</span> */}
          </Link>
        </div>
      </header>
      {loginType === e3.LoginType.User ? (
        <Container maxWidth="sm" className="login_wrap">
          <Typography component="div">
            <Card className="main-login-car">
              <h2 className="typography">
                <img src="login_title.png" />
              </h2>
              <CardContent>
                <FormControl fullWidth className="login-input">
                  <ul>
                    <ISOInput
                      ref={userIdRef}
                      autoFocus={true}
                      required={true}
                      error={true}
                      aria-describedby="userid-error-text"
                      placeholder="사용자계정"
                      value={userid}
                      onKeyPress={async (e) => {
                        if (e.key === "Enter") {
                          await onFinish();
                        }
                      }}
                    />
                    <ISOInput
                      ref={passwordRef}
                      type={values.showPassword ? "text" : "password"}
                      autoFocus={true}
                      required={true}
                      error={true}
                      aria-describedby="password-error-text"
                      placeholder="비밀번호"
                      value={userpw}
                      onKeyPress={async (e) => {
                        if (e.key === "Enter") {
                          await onFinish();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ul>
                </FormControl>

                <FormControl fullWidth className="login-remember">
                  <ISOCheckBox
                    ref={rememberCheckRef}
                    color="primary"
                    label="ID 저장"
                    checked={remember === "true" || false}
                    style={{ minWidth: "100%" }}
                  />
                </FormControl>

                <FormControl fullWidth className="btn_wrap btn_login">
                  <ISOButton ref={buttonRef} fullWidth={true} textLabel={"로그인"} color="primary" onClick={onFinish} />
                </FormControl>
              </CardContent>
            </Card>

            <div className="footer_login">
              <ISOButton onClick={onPrivacyClick}>개인정보처리방침</ISOButton>
              <span>|</span>
              <ISOButton onClick={onEmailClick}>이메일무단수집거부</ISOButton>
              <span>|</span>
              <ISOButton onClick={onSecurityClick}>보안신고</ISOButton>
              <div>
                Copyright ⓒ {process.env.REACT_APP_COPYRIGHTS_YEAR} {process.env.REACT_APP_COMPANY_NAME} All rights
                reserved.​
              </div>
            </div>
          </Typography>
          <HANWHA_COM_PRIVACY_POLICY ref={privacyPop} />
          <HANWHA_COM_EMAIL_COLLECTION ref={emailPop} />
          <HANWHA_COM_SECURITY_DECLARATION ref={securityPop} />
        </Container>
      ) : (
        <></>
      )}
      <MessageOnlyDialog title="로그인 오류" closeDialog={closeDialog} ref={msgRef} />
    </>
  );
});
