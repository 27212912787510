import React, { PureComponent } from "react";
import { FormControlLabel, Radio } from "@material-ui/core";
import PropTypes from "prop-types";

class ISORadio extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <FormControlLabel
          disabled={this.props.readOnly}
          value={this.props.value}
          control={<Radio />}
          label={this.props.textlabel}
        />
      </>
    );
  }
}

ISORadio.propTypes = {
  textlabel: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
};

ISORadio.defaultProps = {
  textlabel: "radio",
  checked: false,
  value: "",
  readOnly: false,
};

export default ISORadio;
