import React from "react";
import { BrowserRouter, Route } from "react-router-dom"; // * BrowserRouter 불러오기
import { ThemeProvider, createTheme, Switch } from "@material-ui/core";
import { Provider } from "react-redux";
import store from "./framework/core/reducers/_store";
import "jqwidgets-scripts/jqwidgets/styles/jqx.base.css";
import "./styles/App.css";
import "./styles/pageLayoutStyle.css";
import "./styles/popupLayoutStyle.css";
import Login from "./pages/index";

const theme = createTheme({
  palette: {
    type: "dark",
  },
});
const isUseTheme = false;

export default function App() {
  if (isUseTheme) {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Login />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    );
  }
  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Login />
      </BrowserRouter>
    </Provider>
  );
}
