import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginForm from "src/framework/layout/LoginForm";
import DefaultLayout from "src/framework/layout/DefaultLayout/index";

export default withRouter(function Login(props) {
  const userInfo = useSelector((state) => state.user);

  useEffect(() => {});

  return userInfo.isLoggedIn ? <DefaultLayout /> : <LoginForm />;
});
