import React, { useRef, useState } from "react";
import PropsType from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import moment from "moment";

registerLocale("ko", ko);

const ISORangeDate = React.forwardRef((props, ref) => {
  const colspan = props.colSpan && props.colSpan > 1 ? props.colSpan : 1;
  const [startDate, setStartDate] = useState(
    props.startDate ? props.startDate : new Date()
  );
  const [endDate, setEndDate] = useState(
    props.endDate ? props.endDate : new Date()
  );
  const [minDate, setMinDate] = useState(
    props.minDate ? props.minDate : undefined
  );
  const [maxDate, setMaxDate] = useState(
    props.maxDate ? props.maxDate : undefined
  );

  const ref_Start = useRef(DatePicker);
  const ref_End = useRef(DatePicker);

  React.useImperativeHandle(ref, () => ({
    getStartDate(format = "YYYY-MM-DD") {
      const date = moment(startDate).format(format);
      return date;
    },
    getEndDate(format = "YYYY-MM-DD") {
      try {
        if (endDate) {
          const date = moment(endDate).format(format);
          return date;
        } else {
          return "";
        }
      } catch {
        return "";
      }
    },
    setStartDate(stringDate) {
      setStartDate(new Date(stringDate));
    },
    setEndDate(stringDate) {
      setEndDate(new Date(stringDate));
    },
    setMinDate(stringDate) {
      setMinDate(new Date(stringDate));
    },
    setMaxDate(stringDate) {
      setMaxDate(new Date(stringDate));
    },
    clearStartDate() {
      setStartDate(null);
    },
    clearEndDate() {
      setEndDate(null);
    },
    clearMinDate() {
      setMinDate(null);
    },
    clearMaxDate() {
      setMaxDate(null);
    },
  }));

  React.useEffect(() => {
    if (props.startDate) {
      setStartDate(new Date(props.startDate));
    }

    if (props.endDate) {
      setEndDate(new Date(props.endDate));
    }
  }, []);

  return (
    <>
      {props.textLabel ? (
        <th>
          <label>{props.textLabel}</label>
        </th>
      ) : (
        ""
      )}
      <td
        className="col-10"
        colSpan={colspan}
        style={{
          verticalAlign: "middle",
          padding: "0px",
          margin: "0px",
        }}
      >
        <div className={"inline-block"} style={{ width: "50%" }}>
          <DatePicker
            {...props}
            style={{ lineHeight: "100%" }}
            ref={ref_Start}
            locale="ko"
            dateFormat={props.dateFormat}
            onChange={(e) => {
              setStartDate(e);
              if (props.startDateChanged) {
                props.startDateChanged(e);
              }
            }}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={endDate}
            readOnly={props.readOnlyStart}
            selectedStart
          />
        </div>
        <div className={"inline-block"} style={{ width: "50%" }}>
          <DatePicker
            {...props}
            ref={ref_End}
            locale="ko"
            dateFormat={props.dateFormat}
            onChange={(e) => {
              setEndDate(e);
              if (props.endDateChanged) {
                props.endDateChanged(e);
              }
            }}
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={maxDate}
            readOnly={props.readOnlyEnd}
            selectedEnd
          />
        </div>
      </td>
    </>
  );
});

ISORangeDate.propTypes = {
  textLabel: PropsType.string,
  colspan: PropsType.number,
  dateFormat: PropsType.string,
  startDateChanged: PropsType.func,
  endDateChanged: PropsType.func,
  disabled: PropsType.bool,
  minDate: PropsType.object,
  maxDate: PropsType.object,
  selectsRange: PropsType.bool,
  monthsShown: PropsType.number, // 달력 표시
  showPreviousMonths: PropsType.bool, // 금일 기준 이전달 표시
  selectsStart: PropsType.bool,
  startDate: PropsType.string,
  selectsEnd: PropsType.bool,
  endDate: PropsType.string,
  width: PropsType.oneOfType([PropsType.string, PropsType.number]),
  height: PropsType.oneOfType([PropsType.string, PropsType.number]),
  labelWidth: PropsType.string,
  readOnlyStart: PropsType.bool,
  readOnlyEnd: PropsType.bool,
};

ISORangeDate.defaultProps = {
  width: "100%",
  height: "100%",
  dateFormat: "yyyy-MM-dd",
  disabled: false,
  labelWidth: "120px",
  readOnlyStart: false,
  readOnlyEnd: false,
};
export default ISORangeDate;
