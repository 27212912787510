import React from "react";
import { Fade } from "@material-ui/core";
import usePageInfo from "../../core/hooks/usePageInfo";
import useMenuLoad from "../../core/hooks/useMenuLoad";
import Loader from "../loaderable/Loader";

const TabPanel = ({ item, hideSideMenuClick }) => {
  const { pageInfo } = usePageInfo();
  const { SetFavoritMenu, LoadFavoritMenu } = useMenuLoad();
  const LoadableTabview = item.contents;
  const displayPath =
    item.NAME_PATH === undefined ? null : item.NAME_PATH.split(">");
  const liArray = !displayPath
    ? null
    : displayPath.map((e, index) => <li key={index}>{e}</li>);
  const [favActive, setFavActive] = React.useState({
    state: false,
    text: "즐겨찾기 추가",
  });
  React.useEffect(async () => {
    try {
      const source = LoadFavoritMenu();
      if (source) {
        const favoritMenu = JSON.parse(source).filter(
          (k) => k.MENU_ID === pageInfo.menuId
        );
        if (favoritMenu.length > 0) {
          setFavActive({
            state: true,
            text: "즐겨찾기 제거",
          });
        }
      }
    } catch (error) {
      console.log("TabPanel useEffect", error.message);
      // throw new Error(error);
    }
  }, []);
  const onClickSetFavorit = async () => {
    if (pageInfo.menuId) {
      if (pageInfo.menuId === "home") return false;
      const ret = await SetFavoritMenu(pageInfo.menuId);
      if (ret && ret.JsonData && JSON.stringify(ret.JsonData) !== "[]") {
        const fMenu = JSON.parse(LoadFavoritMenu()).filter(
          (k) => k.MENU_ID === pageInfo.menuId
        );
        if (fMenu.length > 0) {
          setFavActive({
            state: true,
            text: "즐겨찾기 제거",
          });
        } else {
          setFavActive({
            state: false,
            text: "즐겨찾기 추가",
          });
        }
      }
    }
    return false;
  };

  return (
    <div>
      <div className="main-body-title-area" onMouseUp={hideSideMenuClick}>
        <ul className="title">
          {liArray}
          {item.id === 0 ? "" : <li>{item.PROGRAM_ID}</li>}
        </ul>
        <ul className="title-btn">
          <li className="like">
            <a
              onClick={onClickSetFavorit}
              className={favActive.state ? "selected" : ""}
              title={favActive.text}
            >
              {favActive.text}
              <em className="tooltip">{favActive.text}</em>
            </a>
          </li>
        </ul>
      </div>
      <div style={{ display: item.isActive ? "block" : "none" }}>
        <Fade>
          <LoadableTabview />
        </Fade>
      </div>
    </div>
  );
};

export default TabPanel;
