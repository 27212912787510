import ISOAxios, { DataPackage } from "src/framework/core/apis/ISOAxios";
import { e3, alertType } from "src/framework/core/utils/utils";
import useBase64 from "src/framework/core/hooks/useBase64";

/**
 * 결재자 조회
 * @param {*} userId        :: 아이디
 * @param {*} password      :: 비밀번호
 * @param {*} loginType     :: 로그인 유형
 * @returns
 */
export const doSignIn = async (userId, password, loginType) => {
  try {
    let params = DataPackage.InitSystemDataPackage();
    params.MethodName = "DoSignIn";

    params.KeyValues.push(DataPackage.NewKeyValue("username", userId));
    params.KeyValues.push(DataPackage.NewKeyValue("password", password));
    params.KeyValues.push(DataPackage.NewKeyValue("LOGIN_TYPE", loginType));
    params.Division = "USER";

    return await ISOAxios.Login(params);
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

/**
 * 로그인한 사용자정보를 요청한다.
 * @param {*} Callback (result, error)
 */
export const getProfile = async function (userId) {
  let params = DataPackage.InitSystemDataPackage();
  params.MethodName = "GetUserProfile";

  params.KeyValues.push(
    DataPackage.NewKeyValue("USER_ID", userId || signinUser.USER_ID)
  );
  try {
    const response = await ISOAxios.Execute(params);
    return response;
  } catch (e) {
    return e;
  }
};

/**
 * 메뉴목록 조회
 * @param {*} userId
 * @param {*} plantId
 * @returns
 */
export const getMenus = async (plantId, userId) => {
  let params = DataPackage.InitSystemDataPackage();
  params.MethodName = "GetMenus";
  params.KeyValues.push(DataPackage.NewKeyValue("PLT_ID", plantId || ""));
  params.KeyValues.push(DataPackage.NewKeyValue("USER_ID", userId));
  try {
    const response = await ISOAxios.Execute(params);
    return response;
  } catch (e) {
    console.log("MenuSave", e.message);
    return e;
  }
};

/**
 * 비밀번호 변경
 * @param {*} plantId
 * @param {*} userId
 * @param {*} oldPass
 * @param {*} newPass
 * @returns
 */
export const doChangePassword = async (plantId, userId, oldPass, newPass) => {
  const { base64Encode } = useBase64();

  try {
    let params = DataPackage.InitSystemDataPackage();
    params.MethodName = "ChangePassword";

    params.KeyValues.push(DataPackage.NewKeyValue("PLT_ID", plantId || ""));

    params.KeyValues.push(DataPackage.NewKeyValue("USER_ID", userId));

    params.KeyValues.push(
      DataPackage.NewKeyValue("OLD_PASS", base64Encode(oldPass))
    );

    params.KeyValues.push(
      DataPackage.NewKeyValue("NEW_PASS", base64Encode(newPass))
    );

    const response = await ISOAxios.Execute(params);

    return response;
  } catch (e) {
    return e;
  }
};

/**
 * 즐겨찾기 메뉴 조회
 * @param {*} plantId
 * @param {*} userId
 * @returns
 */
export const getFavoritMenu = async (plantId, userId) => {
  try {
    let params = DataPackage.InitSystemDataPackage();
    params.MethodName = "GetFavoritMenu";

    params.KeyValues.push(DataPackage.NewKeyValue("PLT_ID", plantId || ""));
    params.KeyValues.push(DataPackage.NewKeyValue("USER_ID", userId));
    const response = await ISOAxios.Execute(params);
    return response;
  } catch (e) {
    console.log("GetFavoritMenu", e.message);
    return e;
  }
};

/**
 * 즐겨찾기 추가
 * @param {*} plantId
 * @param {*} userId
 * @param {*} menu_id
 * @returns
 */
export const setFavoritMenu = async (plantId, userId, menu_id) => {
  try {
    let params = DataPackage.InitSystemDataPackage();
    params.MethodName = "SetFavoritMenu";

    params.KeyValues.push(DataPackage.NewKeyValue("PLT_ID", plantId || null));
    params.KeyValues.push(DataPackage.NewKeyValue("USER_ID", userId));
    params.KeyValues.push(DataPackage.NewKeyValue("MENU_ID", menu_id));

    const response = await ISOAxios.Execute(params);

    return response;
  } catch (e) {
    return e;
  }
};

/**
 * profile 수정
 * @param {*} plantId
 * @param {*} userId
 * @param {*} Values
 * @returns
 */
export const doUpdateProfile = async (plantId, userId, Values) => {
  try {
    let params = DataPackage.InitSystemDataPackage();
    params.MethodName = "updateProfile";

    params.KeyValues.push(DataPackage.NewKeyValue("PLT_ID", plantId || ""));

    params.KeyValues.push(DataPackage.NewKeyValue("USER_ID", userId));

    Object.keys(Values).map((it) => {
      params.KeyValues.push(DataPackage.NewKeyValue(it, Values[it]));
    });

    const response = await ISOAxios.Execute(params);

    return response;
  } catch (e) {
    return e;
  }
};

export const getLoginInfo = async (plantId, userId) => {
  try {
    let params = DataPackage.InitSystemDataPackage();
    params.MethodName = "GetLoginInfo";

    params.KeyValues.push(DataPackage.NewKeyValue("PLT_ID", plantId || ""));
    params.KeyValues.push(DataPackage.NewKeyValue("USER_ID", userId));

    const response = await ISOAxios.Execute(params);

    return response;
  } catch (e) {
    if (typeof callback === "function") callback(null, e);
  }
};
