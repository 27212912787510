import React from "react";

export const PopupArea = React.memo((props) => {
  return <div className="popup-area">{props.children}</div>;
});

export const PopupInputArea = React.memo((props) => {
  return <div className="popup-input-area">{props.children}</div>;
});

export const PopupSearchArea = React.memo((props) => {
  return (
    <div className="popup-search-area">
      <div className="popup-search-box">
        <div className="popup-search-box-input">{props.children}</div>
        <div className="popup-search-button">{props.buttons}</div>
      </div>
    </div>
  );
});

export const PopupGridArea = React.memo((props) => {
  return (
    <>
      <div className="popup-grid-area" style={{ ...props.style }}>
        {props.children}
      </div>
    </>
  );
});
