import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ISORoleButton extends React.Component {
  constructor(props) {
    super(props);

    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.setValue = this.setValue.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onClick = this.onClick.bind(this);

    this.state = {
      textLabel: props.textLabel,
      disabled: props.disabled,
      value: props.value,
    };
  }

  enable() {
    if (this.state.disabled) {
      this.setState({
        disabled: false,
      });
    }
  }

  disable() {
    if (!this.state.disabled) {
      this.setState({
        disabled: true,
      });
    }
  }

  setValue(source) {
    if (source) {
      this.setState({
        value: source,
      });
    }
  }

  getValue() {
    return this.state.value;
  }

  setTextLabel(source) {
    this.setState({
      textLabel: source,
    });
  }

  onClick = (e) => {
    if (this.props.onClick !== null) {
      this.props.onClick(e);
    }
  }
  loadAllowButtoneRoles() {
    try {
      const BUTTON_ROLE_CONTROL = process.env.REACT_APP_BUTTON_ROLE_CONTROL || false; // 버튼 권한 설정 적용여부
      if (BUTTON_ROLE_CONTROL) {
        // store에 저장된 tabList 목록 중 Active인 화면의 정보를 가져온다.
        const { tabs } = this.props;
        let activePage = tabs.find((item) => {
          if (item.isActive === true && item.menuId !== 'home') {
            return item;
          }
        });
        if (activePage === undefined || activePage === null || JSON.stringify(activePage) === '{}') {
          activePage = null;
        }
        // console.log('activePage', activePage, this.props);
        if (activePage !== null && activePage.menuId) {
          // store에 저장된 button role 중에 현재 메뉴 아이디에 해당하는 role list를 가져온다.
          const { roles } = this.props;
          let role = roles.find((item) => {
            let key = Object.keys(item)[0];
            if (activePage.menuId === key) {
              return item;
            }
          });
          if (role === undefined || role === null || JSON.stringify(role) === '{}') {
            return this.props.pageRole;
          }
          // console.log('role', role, this.props);
          return role[activePage.menuId];
        }
      }
    } catch (e) {
      console.log(e, this.props);
    }
    return this.props.pageRole;
  }

  setRoleCheck() {
    // buttonType='NONE' 일 경우 권한 검사 진행 여부 (Fasle 일 경우 true 리턴)
    // REACT_APP_BUTTON_ROLE_NONE_CHECK 가 true 이면 buttonRoles에 'NONE'이 포함되어야 한다.
    let noneTypeCheck = process.env.REACT_APP_BUTTON_ROLE_NONE_CHECK === 'true';
    if (this.props.buttonType === 'NONE' && noneTypeCheck === false) return true;

    let buttonRoles = this.loadAllowButtoneRoles();
    // console.log('setRoleCheck', buttonRoles, this.props.buttonType, buttonRoles.indexOf(this.props.buttonType) > -1);
    return buttonRoles.indexOf(this.props.buttonType) > -1;
  }

  render() {
    const { textLabel, buttonType, pageRole, tabs, roles, dispatch, ...other } = this.props;
    const buttonEnable = this.setRoleCheck();
    // 버튼 권한이 없을 경우 화면에 버튼을 출력할 것인지 확인 (true이면 화면에 출력한다.)
    const disableButtonShow = process.env.REACT_APP_BUTTON_ROLE_DISABLE_SHOW === 'true';
    return (
      <>
        {buttonEnable ? (
          <Button
            {...other}
            disabled={this.state.disabled}
            value={this.state.value}
            onClick={this.onClick}
          >
            {this.state.textLabel ? this.state.textLabel : this.props.children ? this.props.children : 'button' }
          </Button>
        ) : disableButtonShow ? (
          <Button
            {...other}
            disabled={true}
            value={this.state.value}
          >
            {this.state.textLabel ? this.state.textLabel : this.props.children ? this.props.children : 'button' }
          </Button>
        ) : (
          <span>&nbsp;</span>
        )}
      </>
    );
  }
}

ISORoleButton.propTypes = {
  textLabel: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  pageRole: PropTypes.array,
};

ISORoleButton.defaultProps = {
  color: 'default',
  component: 'button',
  disabled: false,
  fullWidth: false,
  href: '',
  size: 'medium',
  variant: 'contained',
  value: '',
  onClick: null,
  buttonType: 'NONE',
  pageRole: ['NONE'],
};

let mapStateToProps = (state) => ({ roles: state.pageRole.roles, tabs: state.tabs.tabList });

// export default connect(mapStateToProps)(ISORoleButton);

ISORoleButton = connect(mapStateToProps, null, null, { forwardRef: true })((ISORoleButton));
export default ISORoleButton;
