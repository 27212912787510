import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { e3, alertType, jsonCheck } from "src/framework/core/utils/utils";
import { ISOTableGridCell } from "src/framework/components/controls";
import GridContextMenu from "../GridContextMenu";
import ISOUtils from "src/framework/core/utils/ISOUtils";

class ISOTableGrid extends React.PureComponent {
  contextMenu = React.createRef(GridContextMenu);
  mergeBaseCell = {};
  subTotalMark = {};

  constructor(props) {
    super(props);
    this.state = {
      customRender: false,
      isMultiSelect: props.isMultiSelect ? props.isMultiSelect : false,
      selected: [],
      selectedCell: -1,
      mergeColumns: [],
      columnGroups: [],
      subTotalColumn: {},
      totalColumn: {},
      columns: [],
      source: [],
      originalSource: [],
      headerInfo: [],
      rowInfo: [],
      lastSelectedIndex: -1,
    };
  }

  componentDidMount() {
    if (this.props.allowContext === true) {
      document.addEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    }
  }

  setSubTotalColumn = ({
    summaryType,
    baseField,
    labelField,
    label,
    labelColSpan,
    targetColumns = [],
  }) => {
    this.state.subTotalColumn.summaryType = summaryType;
    this.state.subTotalColumn.baseField = baseField;
    this.state.subTotalColumn.labelField = labelField;
    this.state.subTotalColumn.label = label;
    this.state.subTotalColumn.labelColSpan = labelColSpan;
    this.state.subTotalColumn.targetColumns = targetColumns;
  };

  setTotalColumn = ({
    summaryType,
    labelField,
    label,
    labelColSpan,
    targetColumns = [],
  }) => {
    this.state.totalColumn.summaryType = summaryType;
    this.state.totalColumn.labelField = labelField;
    this.state.totalColumn.label = label;
    this.state.totalColumn.labelColSpan = labelColSpan;
    this.state.totalColumn.targetColumns = targetColumns;
  };

  addMergeColumns = (params) => {
    this.setState({ mergeColumns: params });
  };

  addColumnGroups = (params) => {
    try {
      this.state.columnGroups = params;
      this.initHeaderInfo();
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  addColumns = (params) => {
    try {
      this.state.columns = params;
      this.initHeaderInfo();
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  setDataBinding = async (params) => {
    params = jsonCheck(params);
    try {
      e3.appendProperty(
        params,
        "RowState",
        ISOUtils.tableGrid.RowState.Unchanged
      );

      this.state.originalSource = params;
      let viewSource = params.slice();

      if (
        Object.getOwnPropertyNames(this.state.subTotalColumn).length > 0 &&
        params.length > 0
      ) {
        this.createSubTotalData(viewSource);
      }

      if (
        Object.getOwnPropertyNames(this.state.totalColumn).length > 0 &&
        params.length > 0
      ) {
        this.createTotalData(viewSource);
      }

      this.state.source = viewSource;

      this.createBodyInfo();

      this.setState({ customRender: !this.state.customRender });
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  getRowData = (rowindex) => {
    return this.state.source[rowindex];
  };

  getCellData = (rowIndex, cellIndex) => {
    const columnName = getColumnNameByIndex(cellIndex);
    if (
      this.state.source &&
      this.state.source.length > rowIndex &&
      this.state.source[rowIndex]
    ) {
      return this.state.source[rowIndex][columnName];
    }
    return null;
  };

  getColumnNameByIndex = (cellIndex) => {
    const columnName = this.state.columns.filter((item) => item.visible)[
      cellIndex
    ]?.dataField;
    return columnName || "";
  };

  getColumnLabelByIndex = (cellIndex) => {
    const columnLabel = this.state.columns.filter((item) => item.visible)[
      cellIndex
    ]?.label;
    return columnLabel || "";
  };

  getSelectedRowIndex = () => {
    return this.state.lastSelectedIndex;
  };

  getSelectedCellIndex = () => {
    return this.state.selectedCell;
  };

  onRowClick = async (e) => {
    if (this.props.onRowClick) {
      let currentIndex = parseInt(e.currentTarget.attributes.rowindex.value);
      this.props.onRowClick(e, currentIndex);
    }
  };

  onMouseDown = async (e) => {
    try {
      if (e.nativeEvent.button === 2 && this.props.allowContext === true) {
        this.contextMenu.current.openMenu(e.clientX, e.clientY);
        // const scrollTop = window.scrollY;
        // const scrollLeft = window.scrollX;
        // this.contextMenu.current.open(
        //   parseInt(e.clientX, 10) + 5 + scrollLeft,
        //   parseInt(e.clientY, 10) + 5 + scrollTop,
        // );
      }
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  onCellClick = async (e) => {
    let newSelected = [];
    let currentIndex = parseInt(e.currentTarget.attributes.rowindex.value);
    let currentCellIndex = parseInt(
      e.currentTarget.attributes.columnindex.value
    );
    if (this.state.isMultiSelect) {
      const selectedIndex = this.state.selected.indexOf(currentIndex);

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, currentIndex);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1)
        );
      }
    } else {
      newSelected.push(currentIndex);
    }

    this.state.selected = newSelected;
    this.state.lastSelectedIndex = currentIndex;
    this.state.selectedCell = currentCellIndex;

    this.setState({ customRender: !this.state.customRender });

    if (this.props.onCellClick) {
      this.props.onCellClick(e, this.state.lastSelectedIndex);
    }
  };

  checkSelected = (rowindex, columnindex) => {
    if (this.props.selectionMode === ISOUtils.tableGrid.SelectionMode.Cell) {
      return this.isSelected(rowindex) && this.isSelectedCell(columnindex);
    } else {
      return this.isSelected(rowindex);
    }
  };
  render() {
    return (
      <div className="e3-table-grid">
        <TableContainer>
          <Table
            size="small"
            selectionmode={
              this.props.selectionMode
                ? this.props.selectionMode
                : ISOUtils.tableGrid.SelectionMode.Row
            }
          >
            <TableHead>{this.createHeader()}</TableHead>
            <TableBody>{this.createBody()}</TableBody>
          </Table>
        </TableContainer>

        <GridContextMenu
          ref={this.contextMenu}
          gridType="ISOTableGrid"
          columns={this.state.columns}
          source={this.state.source}
        />
      </div>
    );
  }

  //#region == private function ==

  isSelected = (rowindex) => this.state.selected.indexOf(rowindex) !== -1;
  isSelectedCell = (columnindex) => this.state.selectedCell === columnindex;

  initHeaderInfo = () => {
    if (this.state.columnGroups.length > 0) {
      this.createHeaderInfo();
    }

    this.setState({ customRender: !this.state.customRender });
  };

  createHeaderInfo = () => {
    let headerInfo = [
      {
        cellInfo: [],
      },
      {
        cellInfo: [],
      },
    ];

    headerInfo[1].cellInfo = this.state.columns
      .filter((column) => column.visible && column.columnGroup)
      .map((column) => {
        let cellInfo = {};
        cellInfo.width = column.width;
        cellInfo.label = column.label;
        cellInfo.rowSpan = 1;
        cellInfo.colSpan = 1;
        return cellInfo;
      });

    let beforColumnGroupName = "";

    this.state.columns
      .filter((column) => column.visible)
      .forEach((column) => {
        let cellInfo = {};
        cellInfo.label = column.label;

        if (column.columnGroup) {
          cellInfo.rowSpan = 1;

          if (column.columnGroup === beforColumnGroupName) {
            const lastIndex = headerInfo[0].cellInfo.length - 1;
            const lastColSpan = headerInfo[0].cellInfo[lastIndex].colSpan;
            const lastWidth = headerInfo[0].cellInfo[lastIndex].width;
            headerInfo[0].cellInfo[lastIndex].colSpan = lastColSpan + 1;
            headerInfo[0].cellInfo[lastIndex].width = lastWidth + column.width;
          } else {
            cellInfo.colSpan = 1;
            cellInfo.label = this.state.columnGroups.find(
              (g) => g.name === column.columnGroup
            ).label;
            cellInfo.width = column.width;
            headerInfo[0].cellInfo.push(cellInfo);
          }
          beforColumnGroupName = column.columnGroup;
        } else {
          cellInfo.width = column.width;
          cellInfo.rowSpan = 2;
          cellInfo.colSpan = 1;
          headerInfo[0].cellInfo.push(cellInfo);
        }
      });

    this.state.headerInfo = headerInfo;
  };

  createHeader = () => {
    let headerRows = [];

    if (this.state.columnGroups.length > 0) {
      headerRows = this.state.headerInfo.map((info, rowIndex) => (
        <TableRow key={rowIndex}>
          {info.cellInfo.map((cell, cellIndex) => (
            <TableCell
              key={cellIndex}
              style={{ width: cell.width }}
              align={"center"}
              rowSpan={cell.rowSpan}
              colSpan={cell.colSpan}
            >
              {cell.label}
            </TableCell>
          ))}
        </TableRow>
      ));
    } else {
      headerRows[0] = (
        <TableRow key={0}>
          {this.state.columns
            .filter((column) => column.visible)
            .map((column, colIndex) => (
              <TableCell
                key={colIndex}
                style={{ width: column.width }}
                align={"center"}
              >
                {column.label}
              </TableCell>
            ))}
        </TableRow>
      );
    }

    return headerRows;
  };

  createBodyInfo = async () => {
    try {
      this.state.rowInfo = [];
      this.state.source.map((row, rowindex) => {
        this.createRowInfo(rowindex);

        this.state.columns
          .filter((col) => col.visible)
          .map((col, columnindex) => {
            this.createCellInfo(row, rowindex, col, columnindex);
            this.setMergeInfo(row, rowindex, col, columnindex);
          });

        if (row.RowState === ISOUtils.tableGrid.RowState.SubTotal) {
          this.setSubTotalInfo(rowindex);
        } else if (row.RowState === ISOUtils.tableGrid.RowState.Total) {
          this.setTotalInfo(rowindex);
        }
      });
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  checkMergeCell = (rowindex, columnindex) => {
    let rtnBool = true;
    let startIndex =
      this.mergeBaseCell[Object.keys(this.mergeBaseCell)[0]].columnindex;
    let endIndex = columnindex - startIndex;

    if (rowindex !== 0 && columnindex !== 0) {
      for (let i = 0; i <= endIndex; i++) {
        if (
          this.state.rowInfo[rowindex].cellInfo[columnindex - i].value ===
          this.state.rowInfo[rowindex - 1].cellInfo[columnindex - i].value
        ) {
          rtnBool = true;
        } else {
          rtnBool = false;
          break;
        }
      }
    }

    return rtnBool;
  };

  setMergeInfo = (row, rowindex, col, columnindex) => {
    const cellInfo = this.state.rowInfo[rowindex].cellInfo[columnindex];
    if (this.state.mergeColumns.indexOf(col.dataField) > -1) {
      if (
        rowindex === 0 ||
        row.RowState === ISOUtils.tableGrid.RowState.SubTotal ||
        row.RowState === ISOUtils.tableGrid.RowState.Total
      ) {
        this.mergeBaseCell[col.dataField] = cellInfo;
      } else {
        if (this.mergeBaseCell[col.dataField].value === cellInfo.value) {
          const rtnBool = this.checkMergeCell(rowindex, columnindex);
          if (rtnBool) {
            //if (columnindex === 0 || this.mergeBaseCell[col.dataField].beforCellValue === cellInfo.beforCellValue) {
            const baseRowIndex = this.mergeBaseCell[col.dataField].rowindex;
            const baseColumnIndex =
              this.mergeBaseCell[col.dataField].columnindex;
            const baseRowSpan = this.mergeBaseCell[col.dataField].rowSpan;
            this.state.rowInfo[baseRowIndex].cellInfo[baseColumnIndex].rowSpan =
              baseRowSpan + 1;
            this.state.rowInfo[rowindex].cellInfo[columnindex].visible = false;
          } else {
            this.mergeBaseCell[col.dataField] = cellInfo;
          }
        } else {
          this.mergeBaseCell[col.dataField] = cellInfo;
        }
      }
    }
  };
  setSubTotalInfo = (rowindex) => {
    const cellInfo = this.state.rowInfo[rowindex].cellInfo.find(
      (cell) => cell.dataField === this.state.subTotalColumn.labelField
    );
    cellInfo.colSpan = this.state.subTotalColumn.labelColSpan;
  };

  setTotalInfo = (rowindex) => {
    const cellInfo = this.state.rowInfo[rowindex].cellInfo.find(
      (cell) => cell.dataField === this.state.totalColumn.labelField
    );
    cellInfo.colSpan = this.state.totalColumn.labelColSpan;
  };

  createBody = () => {
    let rows = [];

    this.state.source.map((row, index) => {
      let tableRow = {};
      const isItemSelected = this.isSelected(row);

      if (row.RowState === ISOUtils.tableGrid.RowState.Total) {
        tableRow = (
          <TableRow key={index} rowindex={index}>
            {this.createCell(index, "e3-table-grid-total-row")}
          </TableRow>
        );
      } else if (row.RowState === ISOUtils.tableGrid.RowState.SubTotal) {
        tableRow = (
          <TableRow key={index} rowindex={index}>
            {this.createCell(index, "e3-table-grid-subtotal-row")}
          </TableRow>
        );
      } else {
        tableRow = (
          <TableRow
            key={index}
            rowindex={index}
            selected={isItemSelected}
            onClick={this.onRowClick}
          >
            {this.createCell(index, null)}
          </TableRow>
        );
      }
      rows = rows.concat(tableRow);
    });
    return rows;
  };

  createRowInfo = (rowindex) => {
    this.state.rowInfo[rowindex] = {
      rowindex: rowindex,
      cellInfo: [],
    };
  };

  createCellInfo = (row, rowindex, col, columnindex) => {
    let cellInfo = {};
    cellInfo.rowindex = rowindex;
    cellInfo.columnindex = columnindex;
    cellInfo.dataField = col.dataField;
    cellInfo.rowSpan = 1;
    cellInfo.colSpan = 1;
    cellInfo.visible = true;
    cellInfo.value = row[col.dataField];
    cellInfo.cellRenderer = col.cellRenderer;
    if (columnindex > 0) {
      const beforDataField = this.state.columns.filter((c) => c.visible)[
        columnindex - 1
      ].dataField;
      cellInfo.beforCellValue = row[beforDataField];
    }

    this.state.rowInfo[rowindex].cellInfo[columnindex] = cellInfo;
  };

  createCell = (rowindex, className) => {
    const cellInfo = this.state.rowInfo[rowindex].cellInfo;
    let offset = 1;
    let cells = cellInfo
      .filter((cell) => cell.visible)
      .map((cell, index) => {
        if (offset === 1) {
          offset = cell.colSpan;
          return (
            <ISOTableGridCell
              key={index}
              onClick={this.onCellClick}
              onMouseDown={this.onMouseDown}
              isselected={
                this.checkSelected(rowindex, cell.columnindex)
                  ? "true"
                  : "false"
              }
              value={cell.value}
              rowSpan={cell.rowSpan}
              colSpan={cell.colSpan}
              rowindex={cell.rowindex}
              className={className}
              columnindex={cell.columnindex}
              align={
                this.state.columns.find((c) => c.dataField === cell.dataField)
                  .align
              }
            >
              {this.cellRenderer(cell, index)}
            </ISOTableGridCell>
          );
        } else {
          offset -= 1;
          return null;
        }
      });

    return cells;
  };

  cellRenderer = (cell, index) => {
    try {
      let value = this.appendLineBreak(cell.value);

      if (cell.cellRenderer) {
        value = cell.cellRenderer(
          cell.rowindex,
          cell.columnindex,
          cell.dataField,
          cell.value
        );
      }

      return value;
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  appendLineBreak = (value) => {
    try {
      if (value === null) {
        return <></>;
      } else {
        return (value + "").split("\n").length > 1 ? <pre>{value}</pre> : value;
      }
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  createSubTotalData = (source) => {
    try {
      this.beforRowValue = "";
      this.subTotalMark = Object.assign({}, this.state.subTotalColumn);
      this.subTotalMark.beforRowValue = "";
      this.subTotalMark.targetInfo = [];

      this.state.subTotalColumn.targetColumns.forEach((dataField) => {
        let data = {};
        data.dataField = dataField;
        data.value = 0;

        this.subTotalMark.targetInfo.push(data);
      });

      for (let rowindex = 0; rowindex <= source.length; rowindex++) {
        if (rowindex === source.length) {
          const newRow = this.createSubTotalNewRow(source[0]);
          source.splice(rowindex, 0, newRow);
          break;
        } else if (rowindex > 0) {
          if (
            source[rowindex][this.subTotalMark.baseField] !==
            this.subTotalMark.beforRowValue
          ) {
            const newRow = this.createSubTotalNewRow(source[0]);
            source.splice(rowindex, 0, newRow);
            rowindex++;
          }
        }

        this.subTotalMark.beforRowValue =
          source[rowindex][this.subTotalMark.baseField];
        this.subTotalMark.targetInfo.forEach((data) => {
          data.value =
            (Math.round(parseFloat(data.value) * 100) +
              Math.round(parseFloat(source[rowindex][data.dataField]) * 100)) /
            100;
        });
      }

      this.subTotalMark = {};
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  createTotalData = (source) => {
    try {
      const newRow = e3.cloneObject(source[0]);

      newRow.RowState = ISOUtils.tableGrid.RowState.Total;
      newRow[this.state.totalColumn.labelField] = this.state.totalColumn.label;

      this.state.totalColumn.targetColumns.map((dataField) => {
        newRow[dataField] = this.state.originalSource.reduce(
          (a, v) =>
            (a =
              (Math.round(parseFloat(a) * 100) +
                Math.round(parseFloat(v[dataField]) * 100)) /
              100),
          0
        );
      });

      source.push(newRow);
    } catch (error) {
      e3.modal.alert(alertType.Error, error.message);
    }
  };

  createSubTotalNewRow = (baseObj) => {
    const newRow = e3.cloneObject(baseObj);
    newRow.RowState = ISOUtils.tableGrid.RowState.SubTotal;
    newRow[this.subTotalMark.labelField] = this.subTotalMark.label;
    this.subTotalMark.targetInfo.forEach((info) => {
      newRow[info.dataField] = info.value;
      info.value = 0;
    });
    return newRow;
  };

  //#endregion == private function ==
}

export default ISOTableGrid;
