import React, { useRef, useState } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { alertType, e3 } from "src/framework/core/utils/utils";
import PropsType from "prop-types";

const ISOInputNumber = React.forwardRef((props, ref) => {
  const { textAlign, readOnly } = props;
  const [value, setValue] = useState("");
  const ref_Control = useRef(TextField);

  React.useImperativeHandle(ref, () => ({
    setValue(value) {
      try {
        setValue(value);
      } catch (err) {
        e3.modal.alert(alertType.Error, err.message);
      }
    },
    getValue() {
      try {
        if (value === undefined || value === "") {
          return "";
        } else {
          return parseInt(value);
        }
      } catch (err) {
        e3.modal.alert(alertType.Error, err.message);
      }
    },
    focus() {
      ref_Control.current.focus();
    },
  }));

  React.useEffect(() => {
    setValue(props.value);
  }, []);

  return (
    <>
      <TextField
        inputRef={ref_Control}
        type="number"
        className="ISOInputNumber"
        value={value}
        inputProps={{ readOnly: readOnly, style: { textAlign: textAlign } }}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
});

ISOInputNumber.propTypes = {
  value: PropsType.number,
  readOnly: PropsType.bool,
};

ISOInputNumber.defaultProps = {
  value: 0,
  readOnly: false,
};

export default ISOInputNumber;
