import { e3 } from "src/framework/core/utils/utils";
import { tableGridApi } from "./tableGridApis";

const ISOUtils = {
  ...e3,
  modal: {
    ...e3.modal,
  },
  data: {
    ...e3.data,
  },

  tableGrid: tableGridApi,
};

export default ISOUtils;
