import React, { useState } from "react";
import { ISOPopup } from "src/framework/components/controls";
import { Box, Container, Button } from "@material-ui/core";

const HANWHA_COM_PRIVACY_POLICY = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  React.useImperativeHandle(ref, () => ({
    showDialog() {
      setIsOpen(true);
    },
  }));

  const onClose = async () => {
    setIsOpen(false);
  };

  return (
    <>
      <React.Fragment>
        <ISOPopup
          isOpen={isOpen}
          onClose={onClose}
          dialogId="PRIVACY_POPUP_Dialog"
          title={"개인정보처리방침"}
          width={900}
          height={700}
        >
          <Box className="privacy_wrap2">
            <Container maxWidth="lg">
              <div>
                <form {...props}>
                  <div>
                    <div className="agree_box">
                      <p>
                        '한화임팩트㈜' (이하 회사)은(는) 이용자의 개인정보를 중요시하며, '정보통신망 이용촉진 및
                        정보보호'에 관한 법률과 개인정보보호법 등을 준수하고 있습니다.
                        <br />
                        회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할
                        것입니다.
                        <br />
                        회사의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
                      </p>
                      <p style={{ padding: "0px 0px 0px 20px", lineHeight: "22px" }}>
                        1. 개인정보의 수집 및 이용목적
                        <br />
                        2. 개인정보의 수집 항목 및 수집 방법
                        <br />
                        3. 개인정보의 보유 및 이용 기간
                        <br />
                        4. 개인정보의 제3자 제공
                        <br />
                        5. 개인정보 취급 업무의 위탁
                        <br />
                        6. 정보주체의 권리/의무 및 그 행사방법에 관한 사항
                        <br />
                        7. 개인정보의 파기 절차 및 방법
                        <br />
                        8. 개인정보 자동수집장치의 설치/운영 및 그 거부에 대한 사항
                        <br />
                        9. 개인정보보호를 위한 기술 및 관리적 대책
                        <br />
                        10. 개인정보 보호책임자 작성
                        <br />
                        11. 정책 변경에 따른 공지의무
                      </p>
                      <p>○ 본 방침은부터 2016년 9월 8일부터 시행됩니다.</p>

                      <div className="dot_line"></div>

                      <h4>1.개인정보의 수집 및 이용목적</h4>
                      <p>
                        "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호
                        등의 사항에 의하여 당해 개인을 식별할 수 있는 정보 (당해 정보만으로는 특정 개인을 식별할 수
                        없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사가 고객의
                        개인정보를 수집 이용하는 목적은 다음과 같습니다.
                      </p>
                      <ol className="circle">
                        <li>회원제 서비스 이용에 따른 본인확인, 개인식별, 정보제공</li>
                        <li>불량회원의 부정이용 방지와 비인가 사용 방지</li>
                        <li>가입의사 확인, 가입 및 가입횟수 제한</li>
                        <li>미성년자의 확인</li>
                        <li>고객상담, 고객불만 접수 및 처리, 분쟁조정을 위한 기록보존</li>
                        <li>고지사항 전달</li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>2.수집하는 개인정보 항목 및 수집방법</h4>
                      <ol className="circle">
                        <li>
                          회사는 기본적인 서비스 제공을 위한 필수 정보만을 수집하고 있으며 고객 각각의 기호와 필요에
                          맞는 서비스를 제공하기 위한 정보 수집 시 별도 동의를 득하고 수집하고 있습니다. 선택 정보를
                          입력하지 않은 경우에도 서비스 이용 제한은 없습니다. 회사는 다음과 같이 개인정보를 수집하여
                          이용합니다
                          <ul className="barline">
                            <li>필수정보 : 담당자명, 담당자전화, 담당자 이메일주소, 아이디, 비밀번호</li>
                            <li>선택정보 : 담당자 휴대전화번호</li>
                          </ul>
                        </li>

                        <li>
                          회사는 다음과 같은 방법으로 개인정보를 수집합니다.
                          <ul className="barline">
                            <li>홈페이지에서 이용자 회원가입 시 직접 개인정보 입력/수정</li>
                          </ul>
                        </li>
                        <li>
                          회사는 만14세 미만 아동의 개인정보를 보호하기 위하여 회원가입은 만14세 이상만 가능하도록 하여
                          아동의 개인정보를 수집하지 않습니다.
                        </li>
                        <li>
                          이용자는 동의를 거부할 권리가 있습니다. 동의를 거부할 경우 상기의 정보수집을 필요로 하는 관련
                          서비스를 제공받으실 수 없습니다.
                        </li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>3.개인정보의 보유 및 이용기간</h4>
                      <p>
                        개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 회원 탈퇴 시 수집된
                        개인의 정보가 열람 또는 이용될 수 없도록 파기 처리됩니다. 다만, 상법 및 전자상거래등에서
                        소비자보호에 관한 법률, 전자금융거래법, 여신전문금융업법, 국세기본법, 법인세법, 부가가치세법 등
                        관련 법령의 규정에 의하여 보존할 필요성이있는 경우에는 관계법령에 따라 보존합니다.
                      </p>
                      <ol className="circle">
                        <li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
                        <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>4.개인정보의 제3자 제공</h4>
                      <p>
                        회사는 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는
                        예외로 합니다.
                      </p>
                      <ol className="circle">
                        <li>이용자들이 사전에 동의한 경우</li>
                        <li>
                          법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가
                          있는 경우
                        </li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>5.개인정보 취급 업무의 위탁</h4>
                      <p>모든 개인정보 취급관련 업무를 위탁 하지 않습니다.</p>

                      <div className="dot_line"></div>

                      <h4>6.정보주체의 권리,의무 및 그 행사방법에 관한 사항</h4>
                      <ol className="circle">
                        <li>
                          정보주체는 회사가 처리하는 개인정보 보호 관련 권리를 행사할 수 있습니다.다만, 법률에 특별한
                          규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우에는 요구를 거절할 수 있습니다.
                          <ul className="barline">
                            <li>개인정보 열람요구</li>
                            <li>오류 등이 있을 경우 정정 요구</li>
                            <li>삭제요구</li>
                            <li>처리정지 요구</li>
                          </ul>
                        </li>
                        <li>
                          제1항에 따른 권리 행사는 개인정보관리담당자에게 E-mail로 연락하시면 이에 대해 지체 없이
                          조치하겠습니다.
                        </li>
                        <li>
                          정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를
                          완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
                        </li>
                        <li>
                          제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
                          있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                        </li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>7.개인정보의 파기 절차 및 방법</h4>
                      <p>
                        회사는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및 이용기간에 따라 해당 정보를 지체
                        없이 파기합니다. 파기절차, 방법, 시점은 다음과 같습니다.
                      </p>
                      <ol className="circle">
                        <li>
                          파기절차
                          <ul className="barline">
                            <li>
                              수집된 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라
                              일정 기간 저장된 후 파기되어 집니다.
                            </li>
                            <li>
                              개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용 되지
                              않습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          파기방법
                          <ul className="barline">
                            <li>
                              종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로
                              저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                            </li>
                          </ul>
                        </li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>8.개인정보 자동수집장치의 설치/운영 및 그 거부에 대한 사항</h4>
                      <p>
                        회사는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie) 등 개인정보를 자동으로 수집하는
                        장치를 설치ㆍ운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에
                        보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.회사는 다음과 같은
                        목적을 위해 쿠키 등을 사용합니다.
                      </p>
                      <ol className="circle">
                        <li>
                          쿠키 등 사용 목적
                          <ul className="barline">
                            <li>
                              이용자의 접속 빈도나 방문 시간 등을 분석하거나 관심분야를 파악하여 서비스 개편 등의 척도로
                              활용.
                            </li>
                            <li>
                              이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을
                              설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
                              쿠키의 저장을 거부할 수도 있습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          쿠키 설정 거부 방법
                          <ul className="barline">
                            <li>
                              웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
                              거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
                            </li>
                          </ul>
                        </li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>9.개인정보보호를 위한 기술 및 관리적 대책</h4>
                      <p>회사는 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.</p>
                      <ol className="circle">
                        <li>
                          내부관리계획의 수립 및 시행
                          <ul className="barline">
                            <li>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</li>
                          </ul>
                        </li>
                        <li>
                          개인정보 취급 직원의 최소화 및 교육
                          <ul className="barline">
                            <li>
                              개인정보를 취급하는 담당자를 지정하고 최소화 하여 개인정보를 관리하는 대책을 시행하고
                              있습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          해킹 등에 대비한 기술적 대책
                          <ul className="barline">
                            <li>
                              회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
                              보안프로그램을 설치하고 주기적인 갱신/점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
                              설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          개인정보의 암호화
                          <ul className="barline">
                            <li>
                              이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며
                              중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
                              보안기능을 사용하고 있습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          접속기록의 보관 및 위변조 방지
                          <ul className="barline">
                            <li>
                              개인정보처리시스템에 접속한 기록을(웹 로그, 요약정보 등) 최소 6개월 이상 보관, 관리하고
                              있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          개인정보에 대한 접근 제한
                          <ul className="barline">
                            <li>
                              개인정보를 처리하는 데이터베이스 시스템에 대한 접근권한의 부여, 변경, 말소를 통하여
                              개인정보에 대한 접근통제 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
                              접근을 통제하고 있습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          문서보안을 위한 잠금장치 사용
                          <ul className="barline">
                            <li>
                              개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
                            </li>
                          </ul>
                        </li>
                        <li>
                          비인가자에 대한 출입 통제
                          <ul className="barline">
                            <li>
                              개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립,
                              운영하고 있습니다.
                            </li>
                          </ul>
                        </li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>10.개인정보 보호책임자 작성</h4>
                      <p>
                        회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련
                        부서 및 개인정보 관리책임자를 지정하고 있습니다.
                      </p>
                      <ol className="circle">
                        <li>
                          개인정보관리 책임자
                          <ul className="barline">
                            <li>성명 : 김석숭</li>
                            <li>직급 : 담당</li>
                            <li>이메일 : sskim70@hanwha.com</li>
                          </ul>
                        </li>
                        <li>
                          개인정보 보호 담당부서
                          <ul className="barline">
                            <li>부서명 : 정보기획팀</li>
                            <li>전화번호 : 052-278-6335</li>
                            <li>이메일 : hyunsub.cho@hanwha.com</li>
                          </ul>
                        </li>
                      </ol>
                      <p>
                        이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 민원을 개인정보
                        보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한
                        답변을 드릴것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의
                        하시기 바랍니다.
                      </p>
                      <ol className="circle">
                        <li>개인분쟁조정위원회 (www.1336.or.kr/1336)</li>
                        <li>정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)</li>
                        <li>대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)</li>
                        <li>경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</li>
                      </ol>

                      <div className="dot_line"></div>

                      <h4>11.정책 변경에 따른 공지의무</h4>
                      <p>
                        현 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 수정이
                        있을 시에는 개정 최소 7일전부터 홈페이지의 팝업창 또는 '공지사항'을 통해 고지할 것입니다.
                      </p>
                      <p>
                        개인정보취급방침 버전번호: V20160908
                        <br />
                        개인정보취급방침 변경공고일자: 2016-08-31
                        <br />
                        개인정보취급방침 시행일자: 2016-09-08
                        <br />
                      </p>
                    </div>

                    {/* <div className="agree_ok_btn">
                      <Button color="primary" size="large" variant="contained" onClick={onClose}>
                        확인
                      </Button>
                    </div> */}
                  </div>
                </form>
              </div>
            </Container>
          </Box>
        </ISOPopup>
      </React.Fragment>
    </>
  );
});

export default HANWHA_COM_PRIVACY_POLICY;
