import React from 'react';
import JqxMenu from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxmenu';

class GridContextMenu extends React.PureComponent {
  contextMenu = React.createRef(JqxMenu);

  openMenu = (clientX, clientY) => {
    const scrollTop = window.scrollY;
    const scrollLeft = window.scrollX;
    this.contextMenu.current.open(parseInt(clientX, 10) + 5 + scrollLeft, parseInt(clientY, 10) + 5 + scrollTop);
  };

  render() {
    const onItemClick = (e) => {
      if (e.args.id === 'CSVEXPORT') {
        exportToCSV('csvfile.csv');
      }
    };

    const convertColumns = (gridType, columns) => {
      if (gridType === 'ISOGrid') {
        return columns.map((col) => ({
          ...col,
          visible: !col.hidden,
          label: col.text,
          dataField: col.dafafield,
        }));
      }
      return columns;
    };

    const convertSource = (gridType, source) => {
      if (gridType === 'ISOGrid') {
        return source.records;
      }
      return source;
    };

    const exportToCSV = (filename = 'csvfile.csv') => {
      let { gridType, columns, source } = this.props;

      if (!(columns && columns.length > 0)) {
        return;
      }

      columns = convertColumns(gridType, columns);
      source = convertSource(gridType, source);
      console.log(columns, source);

      let jsonData = {};
      jsonData.columns = [];
      jsonData.records = [];
      // Header
      columns?.forEach((col) => {
        if (col.visible) jsonData.columns.push(`"${col.label}"`);
      });
      // Records
      source?.forEach((item) => {
        let row = [];
        columns.forEach((col) => {
          if (col.visible) row.push(item[col.dataField]);
        });
        for (let i = 0; i < row.length; i++) {
          if (row[i] == null) {
            continue;
          }
          let content = row[i].toString(); //.replaceAll(',', '');
          if (content.startsWith('-')) content = "'" + content;
          row[i] = '"' + content + '"';
        }
        jsonData.records.push(row);
      });

      let separator = ',';
      let csvFileContent = jsonData.columns.join(separator) + '\r\n';
      jsonData.records.forEach((row) => {
        csvFileContent += row.join(separator) + '\r\n';
      });

      if (csvFileContent) {
        const blob = new Blob(['\uFEFF' + csvFileContent], { type: 'text/csv; charset=utf-18' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename);
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', filename);
        if (typeof link.download === 'undefined') {
          link.setAttribute('target', '_blank');
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 100);
      }
    };

    return (
      <JqxMenu
        theme={'material'}
        ref={this.contextMenu}
        onItemclick={onItemClick}
        width={100}
        mode="popup"
        autoOpenPopup={false}
      >
        <ul>
          <li id="CSVEXPORT">CSV Export</li>
        </ul>
      </JqxMenu>
    );
  }
}

export default GridContextMenu;
