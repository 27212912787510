import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const DetailForm = (props) => {
  const { title, isShow, showClose, onClose } = props;

  return (
    <div className="layer-popup" style={{ display: isShow ? "block" : "none" }}>
      <div>
        <span>{title}</span>
        <span>
          {showClose === true ? (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </span>
      </div>
      <div>{isShow ? props.children : null}</div>
    </div>
  );
};

DetailForm.propTypes = {
  title: PropTypes.string,
  isShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
};

DetailForm.defaultProps = {
  title: "title",
  isShow: false,
  showClose: true,
};

export default DetailForm;
