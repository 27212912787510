import React, { useState, useRef } from "react";
import { ISOPopup } from "src/framework/components/controls";
import { Box, Container, Button } from "@material-ui/core";
const HANWHA_COM_EMAIL_COLLECTION = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  React.useImperativeHandle(ref, () => ({
    showDialog() {
      setIsOpen(true);
    },
  }));

  const onClose = async () => {
    setIsOpen(false);
  };

  return (
    <>
      <React.Fragment>
        <ISOPopup
          isOpen={isOpen}
          onClose={onClose}
          dialogId="PRIVACY_POPUP_Dialog"
          title={"이메일무단수집거부"}
          width={900}
          height={380}
        >
          <Box className="email_wrap">
            <Container maxWidth="lg">
              <div>
                <form {...props}>
                  <div>
                    <h3>
                      본 웹 사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여<br />
                      무단으로 수집되는 것을 거부하며 <span>이를 위반 시 정보통신망법에 의해 형사 처벌</span>됨을
                      유념하시기 바랍니다.
                    </h3>
                    <div className="agree_box">
                      <h4>
                        정보통신망법 제 50조의 2<br />
                        (전자우편주소의 무단 수집행위 등 금지)
                      </h4>
                      <ol className="circle">
                        <li>
                          누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로
                          전자우편주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는
                          아니된다.
                        </li>
                        <li>누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매·유통하여서는 아니된다.</li>
                        <li>
                          누구든지 제1항의 및 제2항의 규정에 의하여 수집·판매 및 유통이 금지된 전자우편주소임을 알고
                          이를 정보전송에 이용하여서는 아니된다.
                        </li>
                      </ol>
                    </div>

                    {/* <div className="agree_ok_btn">
                      <Button color="primary" size="large" variant="contained" onClick={onClose}>
                        확인
                      </Button>
                    </div> */}
                  </div>
                </form>
              </div>
            </Container>
          </Box>
        </ISOPopup>
      </React.Fragment>
    </>
  );
});

export default HANWHA_COM_EMAIL_COLLECTION;
