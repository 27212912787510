import React, { PureComponent } from "react";
import { FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import PropTypes from "prop-types";

class ISORadioGroup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }

  setValue(source) {
    this.setState({
      value: source,
    });

    if (this.props.onValueChang) {
      this.props.onValueChang(source);
    }
  }

  getValue() {
    return this.state.value;
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });

    if (this.props.onChange) {
      this.props.onChange(e);
    }

    if (this.props.onValueChang) {
      this.props.onValueChang(e.target.value);
    }
  };

  render() {
    const { textLabel, colSpan, ...other } = this.props;
    const { value } = this.state;
    return (
      <>
        {this.props.textLabel ? (
          <>
            <th>
              <FormLabel component="legend">{this.props.textLabel}</FormLabel>
            </th>
            <td colSpan={colSpan}>
              <RadioGroup
                {...other}
                name={this.props.name}
                value={value}
                onChange={this.onChange}
              >
                {this.props.children}
              </RadioGroup>
            </td>{" "}
          </>
        ) : (
          <>
            <td colSpan={colSpan}>
              <RadioGroup
                {...other}
                name={this.props.name}
                value={value}
                onChange={this.onChange}
              >
                {this.props.children}
              </RadioGroup>
            </td>{" "}
          </>
        )}
      </>
    );
  }
}

ISORadioGroup.propTypes = {
  textLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colSpan: PropTypes.number,
  onChange: PropTypes.func,
};

ISORadioGroup.defaultProps = {
  textLabel: "",
  name: "PropTypes.string",
  value: null,
  colSpan: 1,
  onChange: null,
};

export default ISORadioGroup;
