import React, { useState } from "react";
import PropTypes from "prop-types";
import { Switch } from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  Switch: {
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#ff6600 ",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));

const ISOSwitch = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const setValue = (source) => {
    if (source) {
      setChecked(source);

      if (props.onValueChanged) {
        props.onValueChanged(source);
      }
    }
  };

  const getValue = () => {
    return checked;
  };

  const onChange = (e) => {
    setChecked(e.target.checked);

    if (props.onChange) {
      props.onChange(e);
    }

    if (props.onValueChanged) {
      props.onValueChanged(e.target.checked);
    }
  };

  return (
    <>
      <FormControlLabel
        value={checked}
        control={<Switch className={classes.Switch} onChange={onChange} />}
        label={props.textlabel}
      />
    </>
  );
};

ISOSwitch.propTypes = {
  textlabel: PropTypes.string,
  value: PropTypes.bool,
};

ISOSwitch.defaultProps = {
  textlabel: "radio",
  checked: false,
  value: false,
  readOnly: false,
};
export default ISOSwitch;
