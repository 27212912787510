export const dateBoxArray = [{
  VALUE: 0,
  LABEL: '금일',
}, {
  VALUE: 1,
  LABEL: '전일',
}, {
  VALUE: 2,
  LABEL: '익일',
}, {
  VALUE: 3,
  LABEL: '금주',
}, {
  VALUE: 4,
  LABEL: '전주',
}, {
  VALUE: 5,
  LABEL: '전전주',
}, {
  VALUE: 6,
  LABEL: '금월',
}, {
  VALUE: 7,
  LABEL: '전월',
}, {
  VALUE: 8,
  LABEL: '익월',
}, {
  VALUE: 9,
  LABEL: '기간',
}];
