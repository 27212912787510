import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ISODateTime from './ISODateTime';
import { dateBoxArray } from '../../core/models/arrays';
import ISOCombobox from './ISOCombobox';
import { sleep } from '../../core/utils/utils';

class ISODateBox extends React.PureComponent<{}> {
  combobox;
  startDate;
  endDate;

  constructor(props) {
    super(props);

    this.combobox = React.createRef(ISOCombobox);
    this.startDate = React.createRef(ISODateTime);
    this.endDate = React.createRef(ISODateTime);

    this.state = {
      format: props.format == undefined ? 'YYYY-MM-DD' : props.format,
    };

    this.onComboChange = this.onComboChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getstartDate = this.getstartDate.bind(this);
    this.getEndDate = this.getEndDate.bind(this);
  }

  componentDidMount() {
    if (this.props.isCombobox) {
      this.combobox.current.setDataBinding(dateBoxArray);
    }
  }

  enable = () => {
    this.startDate.current.enable();
    this.endDate.current.enable();
  }

  disable = () => {
    this.startDate.current.disable();
    this.endDate.current.disable();
  }

  setStartDate(value) {
    this.startDate.current.setDate(value);
  }

  setEndDate(value) {
    this.endDate.current.setDate(value);
  }

  getstartDate() {
    return moment(this.startDate.current.getDate()).format(this.state.format);
  }

  getEndDate() {
    return moment(this.endDate.current.getDate()).format(this.state.format);
  }

  onChange = () => {
    if (this.props.onChange) {
      if (!this.props.isCombobox) {
        this.startDate.current.setMaxDate(this.endDate.current.getDate());
        this.endDate.current.setMinDate(this.startDate.current.getDate());
      }

      this.props.onChange({
        startDate: this.getstartDate(),
        endDate: this.getEndDate(),
      });
    }
  }

  onComboChange(e) {
    this.startDate.current.disable();
    this.endDate.current.disable();
    switch (e.value) {
      case 0: // 금일
        this.startDate.current.setDate(moment().toDate());
        this.endDate.current.setDate(moment().toDate());
        break;
      case 1: // 전일
        this.startDate.current.setDate(moment().subtract(1, 'day').toDate());
        this.endDate.current.setDate(moment().subtract(1, 'day').toDate());
        break;
      case 2: // 익일
        this.startDate.current.setDate(moment().subtract(-1, 'day').toDate());
        this.endDate.current.setDate(moment().subtract(-1, 'day').toDate());
        break;
      case 3: // 금주
        this.startDate.current.setDate(moment().startOf('isoWeek').toDate());
        this.endDate.current.setDate(moment().startOf('isoWeek').day(7).toDate());
        break;
      case 4: // 전주
        this.startDate.current.setDate(moment().day(-6).startOf('isoWeek').toDate());
        this.endDate.current.setDate(moment().day(-6).startOf('isoWeek').day(7)
          .toDate());
        break;
      case 5: // 전전주
        this.startDate.current.setDate(moment().day(-12).startOf('isoWeek').toDate());
        this.endDate.current.setDate(moment().day(-12).startOf('isoWeek').day(7)
          .toDate());
        break;
      case 6: // 금월
        this.startDate.current.setDate(moment().subtract(0, 'months').startOf('months').toDate());
        this.endDate.current.setDate(moment().subtract(0, 'months').endOf('month').toDate());
        break;
      case 7: // 전월
        this.startDate.current.setDate(moment().subtract(1, 'months').startOf('months').toDate());
        this.endDate.current.setDate(moment().subtract(1, 'months').endOf('month').toDate());
        break;
      case 8: // 익월
        this.startDate.current.setDate(moment().subtract(-1, 'months').startOf('months').toDate());
        this.endDate.current.setDate(moment().subtract(-1, 'months').endOf('month').toDate());
        break;
      case 9: // 기간
        this.startDate.current.enable();
        this.endDate.current.enable();
        this.startDate.current.setMaxDate(this.endDate.current.getDate());
        this.endDate.current.setMinDate(this.startDate.current.getDate());
        break;
      default:
        break;
    }

    this.onChange();
  }

  render() {
    return (
      <>
        <th>
          <label>{this.props.textLabel}</label>
        </th>
        <td className="col-10">
          <div className={'inline-block col-8'}>{/* ISOCombobox className이 적용안됨 div로 싸서 적용 inline-block:display: 'inline-block', col-8:넓이, pl-3:padding-left:3px */}
            <ISOCombobox
              ref={this.combobox}
              className={'inline-block col-8'}
              onChange={this.onComboChange}
              defaultIndex={6}
            />
          </div>
          <div className={'inline-block col-8 pl-3'}>
            <ISODateTime
              ref={this.startDate}
              disabled={!!this.props.isCombobox}
              onChange={this.onChange}
            />
          </div>
          <div className={'inline-block col-8 pl-3'}>
            <ISODateTime
              ref={this.endDate}
              disabled={!!this.props.isCombobox}
              onChange={this.onChange}
            />
          </div>
        </td>
      </>
    );
  }
}

ISODateBox.propTypes = {
  isCombobox: PropTypes.bool,
  format: PropTypes.string,
  onChange: PropTypes.func,
};

ISODateBox.defaultProps = {
  textLabel: '조회기간',
  isCombobox: true,
};

export default ISODateBox;
