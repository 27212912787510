import { useRadioGroup } from "@material-ui/core";
import ISOAxios, { DataPackage } from "../../framework/core/apis/ISOAxios";
import { alertType, e3, validationType } from "../../framework/core/utils/utils";

export const GetDataPackage = (className, methodName) => {
  let dataPackage = DataPackage.init();
  dataPackage.AssemblyName = "HANWHA.STMS.COM.BIZ.dll";
  dataPackage.Namespace = "HANWHA.STMS.COM.BIZ";
  dataPackage.ClassName = className;
  dataPackage.MethodName = methodName;
  return dataPackage;
};
/**
 * 파일데이터를 읽어 확장자를 반환 합니다.
 * @param {*} dataurl :: Base64 File Data
 * @returns 파일 확장자
 */
export const GetExtensionInBase64Data = (dataurl) => {
  if (dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var ret = mime.split("/")[1];
    switch (ret) {
      case "vnd.openxmlformats-office&lt":
        ret = "xlsx";
        break;
      case "vnd.ms-excel":
        ret = "xls";
        break;
      default:
        break;
    }

    return ret;
  }
};

export const GetExtensionInBase64DataTmp = (dataurl) => {
  if (dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);base64/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var ret = mime.split("/")[1];
    switch (ret) {
      case "vnd.openxmlformats-office&lt;x-document.spreadsheetml.sheet":
        ret = "xlsx";
        break;
      case "vnd.ms-excel":
        ret = "xls";
        break;
      case "vnd.openxmlformats-office&lt;x-document.wordprocessingml.&lt;x-document":
        ret = "docx";
        break;
      case "msword":
        ret = "doc";
        break;
      default:
        break;
    }

    return ret;
  }
};

export const GetCodeComboListByTemp05 = async (pPLANT_ID, pPARENT_ID, pCodeValue) => {
  try {
    let param = GetDataPackage("COM100", "GetCodeComboListByTemp05");
    param.KeyValues.push(DataPackage.NewKeyValue("PLANT_ID", pPLANT_ID));
    param.KeyValues.push(DataPackage.NewKeyValue("PARENT_ID", pPARENT_ID));
    param.KeyValues.push(DataPackage.NewKeyValue("CODE_VALUE", pCodeValue));

    const response = await ISOAxios.Execute(param);
    let data = response.JsonData;
    if (data) {
      return data.length > 0 ? data : null;
    }
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

export const GetPackUnitCodeValue = async () => {
  try {
    let param = GetDataPackage("COM100", "GetCodeValueByPackUnit");

    const result = await ISOAxios.Execute(param);
    if (result) {
      return JSON.parse(result.JsonData);
    }
    return null;
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

export const GetCodeComboListPackUnit = async (pPLANT_ID, pPARENT_ID, pCodeValue) => {
  try {
    let param = GetDataPackage("COM100", "GetCodeComboListPackUnit");
    param.KeyValues.push(DataPackage.NewKeyValue("PLANT_ID", pPLANT_ID));
    param.KeyValues.push(DataPackage.NewKeyValue("PARENT_ID", pPARENT_ID));
    param.KeyValues.push(DataPackage.NewKeyValue("CODE_VALUE", pCodeValue));

    const response = await ISOAxios.Execute(param);
    let data = response.JsonData;
    if (data) {
      return data.length > 0 ? data : null;
    }
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

export const GetCodeComboListByPlantID = async (pPLANT_ID, pPARENT_ID, pParamTemp) => {
  try {
    let param = GetDataPackage("COM100", "GetCodeComboListByPlantID");
    param.KeyValues.push(DataPackage.NewKeyValue("PLANT_ID", pPLANT_ID));
    param.KeyValues.push(DataPackage.NewKeyValue("PARENT_ID", pPARENT_ID));
    param.KeyValues.push(
      DataPackage.NewKeyValue("TEMP_01", pParamTemp && pParamTemp.TEMP_01 ? pParamTemp.TEMP_01 : "")
    );
    param.KeyValues.push(
      DataPackage.NewKeyValue("TEMP_02", pParamTemp && pParamTemp.TEMP_02 ? pParamTemp.TEMP_02 : "")
    );
    param.KeyValues.push(
      DataPackage.NewKeyValue("TEMP_03", pParamTemp && pParamTemp.TEMP_03 ? pParamTemp.TEMP_03 : "")
    );
    param.KeyValues.push(
      DataPackage.NewKeyValue("TEMP_04", pParamTemp && pParamTemp.TEMP_04 ? pParamTemp.TEMP_04 : "")
    );
    param.KeyValues.push(
      DataPackage.NewKeyValue("TEMP_05", pParamTemp && pParamTemp.TEMP_05 ? pParamTemp.TEMP_05 : "")
    );

    const response = await ISOAxios.Execute(param);
    let data = response.JsonData;

    return data.length > 0 ? data : null;
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

//일수 차이 구하는 함수
export const GetDiffDate = async (pStartDate, pEndDate) => {
  try {
    const date1 = new Date(pStartDate);
    const date2 = new Date(pEndDate);
    const diffDate = date1.getTime() - date2.getTime();

    const diff = Math.abs(diffDate / (1000 * 60 * 60 * 24));
    return diff + 1;
  } catch (e) {
    e3.modal.alert(alertType.error, e.message);
  }
};

//운송사 DropdownList용
export const GetCarrierCodes = async (plantId) => {
  try {
    let params = GetDataPackage("COM100", "GetCarrierCodes");
    params.KeyValues.push(DataPackage.NewKeyValue("plantId", plantId));
    const result = await ISOAxios.Execute(params);
    if (result) return JSON.parse(result.JsonData);
    return null;
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

export const GetComboCodeByStartPoint = async (pPLANT_ID, pPARENT_ID, pTEMP_01) => {
  try {
    let param = GetDataPackage("COM100", "GetComboCodeStartPoint");
    param.KeyValues.push(DataPackage.NewKeyValue("PLANT_ID", pPLANT_ID));
    param.KeyValues.push(DataPackage.NewKeyValue("PARENT_ID", pPARENT_ID));
    param.KeyValues.push(DataPackage.NewKeyValue("TEMP_01", pTEMP_01));
    const response = await ISOAxios.Execute(param);
    let data = response.JsonData;
    if (data) {
      return data.length > 0 ? data : null;
    }
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

export const GetLgortCodeList = async () => {
  try {
    let param = GetDataPackage("COM100", "GetLgortCodeList");
    const response = await ISOAxios.Execute(param);
    let data = response.JsonData;
    if (data) {
      return data.length > 0 ? JSON.parse(data) : null;
    }
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};

export const GetDelStatusCodeList = async () =>{
  try {
    let param = GetDataPackage("COM100", "GetDelStatusCodeList");
    const response = await ISOAxios.Execute(param);
    let data = response.JsonData;
    if (data) {
      return data.length > 0 ? JSON.parse(data) : null;
    }
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
}

//plantId로 공장정보 불러오기
export const GetPlantNmByPlantId = async (pPLANT_ID) => {
  try {
    let param = GetDataPackage("COM100", "GetPlantNmByPlantId");
    param.KeyValues.push(DataPackage.NewKeyValue("PLANT_ID", pPLANT_ID));
    const response = await ISOAxios.Execute(param);

    if (response) return JSON.parse(response.JsonData)[0].PLANT_NM;
    return null;
  } catch (error) {
    e3.modal.alert(alertType.Error, error.message);
  }
};
