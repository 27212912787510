import React from "react";
import PropTypes, { object } from "prop-types";
import Select from "react-select";
import ISOAxios, { DataPackage } from "../../core/apis/ISOAxios";
import { KingBed } from "@material-ui/icons";

class ISOCombo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reload: false,
            textLabel: props.textLabel,
            selectedValue: this.props.defaultValue,
            source: [],
            disabled: this.props.disabled,
            previousValue: undefined,
        };
    }

    async setDataBinding(source) {
        if (source) {
            let bindingSource = [...(typeof source === "string" ? JSON.parse(source) : source)];

            if (this.props.isAddAll) {
                bindingSource = this.addAll(bindingSource);
            }

            let convertSource = bindingSource.map((e) => ({
                label: e[this.props.displayMember],
                value: e[this.props.valueMember],
            }));

            await this.setState({
                source: convertSource,
            });
        }
        if (this.state.disabled) {
            this.disable();
        }
    }

    addAll(jsonResult) {
        const allData = {};

        if (this.props.displayMember) {
            allData[this.props.displayMember] = this.props.allLabel;
        } else {
            allData["LABEL"] = this.props.allLabel;
        }

        if (this.props.valueMember) {
            allData[this.props.valueMember] = "";
        } else {
            allData["VALUE"] = "";
        }

        jsonResult.unshift(allData);
        return jsonResult;
    }

    async componentWillMount() {
        if (this.props.codeId) {
            let params = DataPackage.create("SYS100", "getCodes");
            params.KeyValues.push(DataPackage.NewKeyValue("CODE_ID", this.props.codeId));

            if (this.props.codeTemps) {
                Object.keys(this.props.codeTemps).forEach((key) => {
                    params.KeyValues.push(DataPackage.NewKeyValue(key, this.props.codeTemps[key]));
                });
            }

            if (this.props.plantId) {
                params.KeyValues.push(DataPackage.NewKeyValue("plantId", this.props.plantId));
            }

            let result = (await ISOAxios.Execute(params)).JsonData;

            if(result){
                if (this.props.filter) {
                    result = JSON.parse(result).filter((item) => {
                        if (this.props.filter.indexOf(item.VALUE) > -1) {
                            return item;
                        }
                    });
                }
            }
            this.setDataBinding(result);
        }
    }

    getValue() {
        let value = null;

        if (this.props.multiable) {
            value = this.state.selectedValue;
        } else {
            value = this.state.selectedValue;
        }
        return value;
    }

    async setValue(value) {
        this.setState({ selectedValue: value });
    }

    enable() {
        if (this.state.disabled) {
            this.setState({
                disabled: false,
            });
        }
    }

    disable() {
        if (!this.state.disabled) {
            this.setState({
                disabled: true,
            });
        }
    }

    async onChange(e) {
        this.setState({ reload: !this.state.reload });
        this.state.selectedValue = e.value;

        if (this.state.previousValue !== e.value) {
            if (this.props.onValueChanged) {
                this.props.onValueChanged(e.value);
            }
            this.state.previousValue = e.value;
        }

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        return (
            <>
                <Select
                    className="iso-e3-select"
                    classNamePrefix="select"
                    styles={{ menu: (provided) => ({ ...provided, zIndex: 8999 }) }}
                    placeholder={this.props.placeholder && this.props.placeholder}
                    isDisabled={this.state.disabled}
                    menuPlacement={this.props.menuPlacement}
                    options={this.state.source}
                    onChange={(e) => this.onChange(e)}
                    value={this.state.source.find((op) => {
                        return op.value === this.state.selectedValue;
                    })}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                />
            </>
        );
    }
}

ISOCombo.propTypes = {
    placeholder: PropTypes.string,
    codeId: PropTypes.string,
    plantId: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    displayMember: PropTypes.string,
    valueMember: PropTypes.string,
    onChange: PropTypes.func,
    isAddAll: PropTypes.bool,
    allLabel: PropTypes.string,
    multiable: PropTypes.bool,
    menuPlacement: PropTypes.oneOf(["auto", "top"]),
};

ISOCombo.defaultProps = {
    menuPlacement: "auto",
    clearable: false,
    searchable: false,
    disabled: false,
    multiable: false,
    displayMember: "LABEL",
    valueMember: "VALUE",
    onChange: null,
    isAddAll: false,
    allLabel: "전체",
};

export default ISOCombo;
