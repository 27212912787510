import { useDispatch } from "react-redux";
import useLocalStorage from "./useLocalStorage";
import useSessionStorage from "./useSessionStorage";
import ISOAxios, { DataPackage } from "../apis/ISOAxios";
import { dataList, dataReset } from "../reducers/FavoritMenuReducer";
import { menuList, menuReset } from "../reducers/MenuReducer";
import { alertType, e3 } from "src/framework/core/utils/utils";
import {
  getMenus,
  getFavoritMenu,
  setFavoritMenu,
} from "src/framework/core/handlers/SystemAction";

/**
 * 프로그램 메뉴 정보를 API Server에서 가져온다
 * 로컬 스토리지에서 저장, 제거, 읽기를 실행한다.
 * 데이타베이스으 복잡한 쿼리 없이 Flat2Hierarchy를 지원
 */
export default function useMenuLoad() {
  const dispathcer = useDispatch();
  const dispatchFavoritMenu = (data) => dispathcer(dataList(data));
  const dispatchFavoritMenuReset = () => dispathcer(dataReset());
  const dispatchMenu = (data) => dispathcer(menuList(data));
  const dispatchMenuReset = () => dispathcer(menuReset());
  const { loadSessionStorage } = useSessionStorage("loginSign");
  const { localStorageSave, localStorageLoad, localStorageRemove } =
    useLocalStorage();

  const MenuSave = async (userId, plantId) => {
    try {
      const response = await getMenus(plantId, userId);
      if (response) {
        localStorageRemove("MENU");
        localStorageSave("MENU", response.JsonData);
        const jsonData = JSON.parse(response.JsonData);
        dispatchMenu(jsonData);
      }
      return response;
    } catch (e) {
      console.log("MenuSave", e.message);
      return e;
    }
  };

  const MenuLoad = () => localStorageLoad("MENU");
  const MenuRemove = () => {
    localStorageRemove("MENU");
    dispatchMenuReset();
  };

  const Flat2Hierarchy = () => {
    const flatMenu = JSON.parse(MenuLoad());
    return Flat2Hierarchy4Args(flatMenu);
  };

  const Flat2Hierarchy4Args = (flatMenu) => {
    const hierarchyMenu = [];
    const mappedArr = {};
    let arrElem;
    let mappedElem;
    try {
      if (flatMenu.length > 0) {
        for (let i = 0, len = flatMenu.length; i < len; i++) {
          arrElem = flatMenu[i];
          mappedArr[arrElem.MENU_ID] = arrElem;
          mappedArr[arrElem.MENU_ID].children = [];
        }

        for (const MENU_ID in mappedArr) {
          if (mappedArr.hasOwnProperty(MENU_ID)) {
            mappedElem = mappedArr[MENU_ID];
            if (mappedElem.PARENT_ID) {
              try {
                mappedArr[mappedElem.PARENT_ID].children.push(mappedElem);
              } catch (e) {
                e3.modal.alert(
                  `${alertType.Error}[Flat2Hierarchy4Args]`,
                  e.message
                );
              }
            } else {
              hierarchyMenu.push(mappedElem);
            }
          }
        }
      }
    } catch (e) {
      e3.modal.alert(alertType.Error, e.ErrorMessage);
    }
    return hierarchyMenu;
  };

  const GetFavoritMenu = async (userId, plantId) => {
    try {
      const response = await getFavoritMenu(plantId, userId);
      if (response) {
        localStorageRemove("FAVORITMENU");
        localStorageSave("FAVORITMENU", response.JsonData);
        const jsonData = JSON.parse(response.JsonData);
        dispatchFavoritMenu(jsonData);
      }
      return response;
    } catch (e) {
      console.log("GetFavoritMenu", e.message);
      return e;
    }
  };
  const SetFavoritMenu = async (MENU_ID) => {
    const signinUser = loadSessionStorage();
    try {
      const response = await setFavoritMenu(
        signinUser.PLT_ID,
        signinUser.USER_ID,
        MENU_ID
      );
      if (response) {
        localStorageRemove("FAVORITMENU");
        localStorageSave("FAVORITMENU", response.JsonData);
        const jsonData = JSON.parse(response.JsonData);
        dispatchFavoritMenu(jsonData);
      }
      return response;
    } catch (e) {
      return e;
    }
  };
  const LoadFavoritMenu = () => localStorageLoad("FAVORITMENU");
  const RemoveFavoritMenu = () => {
    localStorageRemove("FAVORITMENU");
    dispatchFavoritMenuReset();
  };

  return {
    MenuSave,
    MenuLoad,
    MenuRemove,
    GetFavoritMenu,
    SetFavoritMenu,
    LoadFavoritMenu,
    RemoveFavoritMenu,
    Flat2Hierarchy,
    Flat2Hierarchy4Args,
  };
}
