import React, { useEffect, useRef, useState } from "react";
import * as ISO from "src/framework/layout/LayerComponents.js";
import ISOButton from "./ISOButton";
import ISOInput from "./ISOInput";
import ISOGrid from "./ISOGrid";
import { e3 } from "src/framework/core/utils/utils";
import { GetDataPackage, GetExtensionInBase64Data } from "src/pages/HANWHA/HANWHA_COM_ACTION";
import ISOAxios, { DataPackage } from "src/framework/core/apis/ISOAxios";
import imageCompression from "browser-image-compression";

/**
 * 운송사 찾기
 * @returns {JSX.Element}
 * @constructor
 */
const ISOAttachGrid = React.forwardRef((props, ref) => {
  /**일반 변수 */

  /**그리드 변수 */
  const attachGrid = useRef(ISOGrid);

  const ref_Attach = useRef(ISOInput); //첨부

  const btnFileSelect = useRef(ISOButton);
  const btnFileDelete = useRef(ISOButton);

  const columns_attach = [
    e3.grid.setColumn({
      text: "파일 아이디",
      datafield: "ATTACH_ID",
      cellsalign: "center",
      width: 200,
      hidden: true,
    }),
    e3.grid.setColumn({
      text: "파일",
      datafield: "ATTACH_FILE",
      cellsalign: "center",
      width: 200,
      hidden: true,
    }),
    e3.grid.setColumn({
      text: "파일명",
      datafield: "ATTACH_FILE_NM",
      cellsalign: "center",
      width: "75%",
    }),
    e3.grid.setColumn({
      text: "다운로드",
      width: "10%",
      datafield: "attach",
      cellsalign: "center",
      columntype: "button",
      buttonclick: (rowIndex, e) => {
        onClickBookingDoc(rowIndex, e);
      },
      cellsrenderer: (rowid, datafield, value) => {
        return "다운로드";
      },
    }),
  ];

  React.useImperativeHandle(ref, () => ({
    getAttachSource() {
      return attachGrid.current.getDataSource();
    },
    setAttachSourceBinding(_source) {
      attachGrid.current.setDataBinding(_source);
    },
  }));

  React.useEffect(() => {
    if (props.mode) {
      if (props.mode == "VIEW") {
        btnFileSelect.current.disable();
        btnFileDelete.current.disable();
      }
    }
  }, []);

  //첨부파일 선택 클릭 이벤트
  const onSelectBookingFileClick = async () => {
    let param = GetDataPackage("COM100", "GetAttachExtType");
    param.KeyValues.push(DataPackage.NewKeyValue("PARENT_ID", "ATTACH_EXT_TYPE"));

    let type = [];
    const response = await ISOAxios.Execute(param);
    let data = JSON.parse(response.JsonData);
    if (data.length > 0) {
      data.map((r) => {
        type.push(`.${r.CODE_VALUE}`);
      });
    } else {
      type = [".xlsx", ".xls", ".doc", ".docx", ".pdf", ".png"];
    }

    let tmp = {
      // "application/xlsx": [".xlsx",".xls",".doc",".docx",".pdf"], //Extensions you want to allow
      "application/xlsx": type,
    };

    if (typeof window.showOpenFilePicker !== "function") {
      ref_Attach.current.accept = type;
      ref_Attach.current.click();
    } else {
      let files = await window.showOpenFilePicker({
        types: [
          {
            description: "Files",
            accept: tmp,
          },
        ],
        excludeAcceptAllOption: true, // this hides all files option
        multiple: true,
      });

      const grdSource = attachGrid.current.getDataSource();
      let gridArry = [];
      if (grdSource) {
        gridArry = [...grdSource];
      }

      await Promise.all(
        files.map(async (fileHandle) => {
          const file = await fileHandle.getFile();
          let gridJson = "";
          if (file.type.indexOf("image") === "0") {
            let res = await handleFileOnChange(file);
            gridJson = {
              ATTACH_FILE: await e3.fileToBase64Async(res),
              ATTACH_FILE_NM: res.name,
            };
          } else {
            gridJson = {
              ATTACH_FILE: await e3.fileToBase64Async(file),
              ATTACH_FILE_NM: file.name,
            };
          }
          gridArry.push(gridJson);
        })
      );
      attachGrid.current.setDataBinding(gridArry);
    }
  };

  //이미지 파일 리사이즈
  const handleFileOnChange = async (file) => {
    // 이미지 resize 옵션 설정 (최대 width을 2400px로 지정)
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 2400,
    };
    try {
      const compressedFile = await imageCompression(file, options);

      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  };

  //첨부파일 그리드에서 지우기
  const onDeleteAttachFile = () => {
    const row = attachGrid.current.getSelectedrows();
    for (var i = 0; i < row.length; i++) {
      attachGrid.current.deleteRowByIndex(row[i].boundindex);
    }
  };

  //첨부파일 다운로드
  const onClickBookingDoc = (idx) => {
    const rowData = attachGrid.current.getRowData(idx);
    let _fileNm = rowData.ATTACH_FILE_NM;
    let a = document.createElement("a");
    a.href = rowData.ATTACH_FILE;
    a.download = _fileNm;
    a.click();
  };

  const fileOnChange = async (e) => {
    const grdSource = attachGrid.current.getDataSource();
    let gridArry = [];
    if (grdSource) {
      gridArry = [...grdSource];
    }
    for (var i = 0; i < e.target.files.length; i++) {
      let gridJson = {
        ATTACH_FILE: await e3.fileToBase64Async(e.target.files[i]),
        ATTACH_FILE_NM: e.target.files[i].name,
      };
      gridArry.push(gridJson);
    }
    attachGrid.current.setDataBinding(gridArry);
  };

  return (
    <>
      <ISO.PopupInputArea>
        <div>
          <div style={{ float: "left" }}>
            <span className="f_note">첨부파일 목록</span>
          </div>
          <div style={{ float: "right", paddingBottom: "5px" }}>
            <ISO.ButtonArea>
              <ISOButton ref={btnFileSelect} onClick={onSelectBookingFileClick}>
                파일선택
              </ISOButton>
              <ISOButton ref={btnFileDelete} onClick={onDeleteAttachFile}>
                파일삭제
              </ISOButton>
            </ISO.ButtonArea>
            <input
              ref={ref_Attach}
              type={"file"}
              style={{ display: "none" }}
              id="fileBookingAttach"
              multiple
              accept=".xlsx,.xls,.doc,.docx,.pdf"
              onChange={fileOnChange}
            />
          </div>
        </div>
      </ISO.PopupInputArea>
      <ISO.PopupGridArea style={{ padding: "10px 18px" }}>
        <ISOGrid ref={attachGrid} columns={columns_attach} selectionmode="checkbox" height={props.gridHeight} />
      </ISO.PopupGridArea>
    </>
  );
});

export default ISOAttachGrid;
