import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ISOButton from "./ISOButton";
import { divName, e3 } from "../../core/utils/utils";

class ISOAlert extends PureComponent<> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  close = () => {
    this.setState({
      isOpen: false,
    });

    if (this.props.onCloseCallback) {
      this.props.onCloseCallback();
    }
    e3.ui.removeElementReconfirm(divName.alertDivName);
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">{this.props.title ? this.props.title : this.props.children}</DialogTitle> */}
          <DialogContent className="alert" fullWidth={true}>
            <DialogContentText id="alert-dialog-description">
              <Alert severity={this.props.alertType}>
                <AlertTitle>{this.props.alertType}</AlertTitle>
                {this.props.contents.split("\n").map((line) => (
                  <div>{line}</div>
                ))}
              </Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ISOButton onClick={this.close}>확인</ISOButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ISOAlert.propTypes = {
  alertType: PropTypes.string,
  contents: PropTypes.string,
};

ISOAlert.defaultProps = {
  alertType: "error",
  contents: "내용",
};

export default ISOAlert;
