import React from "react";
import { useState } from "react";
import * as ISO from "src/framework/layout/LayerComponents";

export const PageArea = React.memo((props) => {
    return <ISO.OLayer className="main-body-content">{props.children}</ISO.OLayer>;
});

export const PageSearchArea = React.memo((props) => {
    return (
        <ISO.OSearchBox>
            <div className="input_search">{props.children}</div>
            <div className="btn_wrap">{props.buttons}</div>
        </ISO.OSearchBox>
    );
});

export const PageInputArea = React.memo((props) => {
    return (
        <ISO.OFormBox>
          {/* <div className="input_record">{props.children}</div> */}
          <div>{props.children}</div>
        </ISO.OFormBox>

      
        
    );
});

export const AutoHeightArea = React.memo((props) => {
    return (
        <ISO.OResultBox autoHeight={true}>
            <ISO.ResultBoxNoBorder>{props.children}</ISO.ResultBoxNoBorder>
        </ISO.OResultBox>
    );
});

// export const PageArea = React.memo((props) => {
//   return (
//     <>
//       <section className={"page-area main-body-content"}>
//         {props.children}
//       </section>
//     </>
//   );
// });

// export const PageSearchArea = React.memo((props) => {
//   return (
//     <div className="main-body-search-area">
//       <div className="main-body-search-box">
//         <div className="input_search">{props.children}</div>
//         <div className="btn_wrap">{props.buttons}</div>
//       </div>
//     </div>

//     // <div className="page-search-area">
//     //   <div className="page-search-box">
//     //     <div className="page-search-box-input">{props.children}</div>
//     //     <div className="page-search-button">{props.buttons}</div>
//     //   </div>
//     // </div>
//   );
// });

// export const PageInputArea = React.memo((props) => {
//   return <div className="page-input-area">{props.children}</div>;
// });

// export const AutoHeightArea = React.memo((props) => {
//   const searchBoxHeight = document.querySelector(
//     ".tab-content.active .main-body-search-area"
//   )
//     ? document.querySelector(".tab-content.active .main-body-search-area")
//         .clientHeight
//     : 0;

//   const boxAreaHeight = document.querySelector(".input_record")
//     ? document.querySelector(".input_record").clientHeight
//     : 0;

//   return (
//     <div
//       style={{
//         height: `calc(100vh - 144px - ${searchBoxHeight}px - ${boxAreaHeight}px)`,
//         padding: "0px 20px",
//         ...props.style,
//       }}
//     >
//       {props.children}
//     </div>
//   );
// });

export const ISOTr = React.forwardRef((props, ref) => {
    const [hidden, setHidden] = useState(false);

    React.useImperativeHandle(ref, () => ({
        setHidden(b) {
            return setHidden(b);
        },
    }));

    return <tr hidden={hidden}>{props.children}</tr>;
});
