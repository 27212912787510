import React, { useState } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import JqxSplitter from "jqwidgets-scripts/jqwidgets-react-tsx/jqxsplitter";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  Slide,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    cursor: "move",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    cursor: "pointer",
  },
});

const stylesAction = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  leftButton: {
    justifyContent: "right",
  },
  centerButton: {
    justifyContent: "center",
  },
  rightButton: {
    justifyContent: "flex-end",
  },
});

function PaperComponent(props) {
  const handleId = `#${props["aria-labelledby"]}`;
  return (
    <Draggable handle={handleId} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ISOPopupTitle = withStyles(styles)((props) => {
  const { classes, children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ISOPopupContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ISOPopupActions = withStyles(stylesAction)((props) => {
  const { classes, children, buttonAlign, ...other } = props;
  const alignClass =
    buttonAlign === "left"
      ? classes.leftButton
      : buttonAlign === "center"
      ? classes.centerButton
      : classes.rightButton;
  return (
    <MuiDialogActions className={(classes.root, alignClass)} {...other}>
      {children}
    </MuiDialogActions>
  );
});

const ISOPopup = (props) => {
  const {
    width,
    height,
    title,
    onClose,
    isOpen,
    dialogId,
    children,
    actionButtons,
  } = props;
  return (
    <div className="dialog-customer-modal">
      <Dialog
        onClose={onClose}
        open={isOpen}
        className="modal-ok-close"
        aria-labelledby={dialogId}
        PaperComponent={PaperComponent}
        maxWidth={false}
        TransitionComponent={Transition}
      >
        <div style={{ width: width, height: height }}>
          <ISOPopupTitle onClose={onClose} id={dialogId}>
            {title}
          </ISOPopupTitle>
          <ISOPopupContent className="dialog-content-body">
            {children}
          </ISOPopupContent>
          <ISOPopupActions buttonAlign={props.buttonAlign}>
            {actionButtons ? actionButtons : <></>}
          </ISOPopupActions>
        </div>
      </Dialog>
    </div>
  );
};

ISOPopup.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  dialogId: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.string,
};
ISOPopup.defaultProps = {
  height: 600,
  width: 400,
  title: "title",
};

export default ISOPopup;
