import React from 'react';
import ISODateTime from './ISODateTime';

class ISODateRange extends React.PureComponent<{}> {
  startDate;
  endDate;

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.startDate = React.createRef(ISODateTime);
    this.endDate = React.createRef(ISODateTime);
  }

  /**
   * component Loaded
   */
  componentDidMount() {
    const startDate = this.startDate.current.getDate();
    // this.startDate.current.setStartDate(startDate);

    const endDate = this.endDate.current.getDate();
    // this.endDate.current.setStartDate(startDate);
    this.endDate.current.setEndDate(endDate);
    this.startDate.current.setEndDate(endDate);
  }

  /**
   * 시작일자 return
   * @returns {*}
   */
  getStartDate = () => this.startDate.current.getDate()

  /**
   * 종료일자 return
   * @returns {ISODateRange}
   */
  getEndDate = () => this.endDate.current.getDate()

  /**
   * startDate onChange
   * @param e
   */
  onStartDateChange = (e) => {
    if(e) {
      this.startDate.current.setStartDate(e);
      this.startDate.current.setEndDate(this.endDate.current.getDate());
      this.endDate.current.setStartDate(e);

      this.endDate.current.onOpen();
    }
  }

  /**
   * endDate onChange
   * @param e
   */
  onEndDateChange = (e) => {
    const startDate = this.startDate.current.getDate(false);
    if(e) {
      if (startDate > e) {
        this.startDate.current.clear();
        this.startDate.current.onOpen();
        this.startDate.current.setDate(e);
        this.endDate.current.clear();
      } else {
        this.endDate.current.setEndDate(e);
      }
    }
  }

  render() {
    const colspan = this.props.colSpan && this.props.colSpan > 1 ? this.props.colSpan : 1;
    return (
      <>
        {this.props.textLabel
          ? (
            <th>
              <label>{this.props.textLabel}</label>
            </th>
          )
          : ''}
        <td className="col-10" colSpan={colspan}>
          <div className={'inline-block col-12 pr-3'}>
            <ISODateTime
              ref={this.startDate}
              selectsStart
              monthsShown={2}
              onChange={this.onStartDateChange}
            />
          </div>
          <div className={'inline-block col-12 pl-3'}>
            <ISODateTime
              ref={this.endDate}
              selectsEnd
              monthsShown={2}
              onChange={this.onEndDateChange}
            />
          </div>
        </td>
      </>
    );
  }
}

ISODateRange.defaultProps = {
  colSpan: 1,
};

export default ISODateRange;
