import React, { useState } from "react";
import { ISOPopup } from "src/framework/components/controls";
import { Box, Container, Button } from "@material-ui/core";

const HANWHA_COM_SECURITY_DECLARATION = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  React.useImperativeHandle(ref, () => ({
    showDialog() {
      setIsOpen(true);
    },
  }));

  const onClose = async () => {
    setIsOpen(false);
  };

  return (
    <>
      <React.Fragment>
        <ISOPopup
          isOpen={isOpen}
          onClose={onClose}
          dialogId="PRIVACY_POPUP_Dialog"
          title={"보안신고"}
          width={900}
          height={270}
        >
          <Box className="email_wrap">
            <Container maxWidth="lg">
              <div>
                <form {...props}>
                  <div>
                    <h3>
                      귀하의 소중한 의견은{" "}
                      <span>한화임팩트의 핵심기술 및 경영정보 보호는 물론 국내 산업의 경쟁력을 강화하고<br />경제 발전</span>
                      에 이바지하는데 초석이 됩니다.
                    </h3>
                    <div className="agree_box">
                      <h4>제보 이메일</h4>
                      <p style={{ fontSize: "18px" }}>security.hgc@hanwha.com</p>
                    </div>

                    {/* <div className="agree_ok_btn">
                      <Button color="primary" size="large" variant="contained" onClick={onClose}>
                        확인
                      </Button>
                    </div> */}
                  </div>
                </form>
              </div>
            </Container>
          </Box>
        </ISOPopup>
      </React.Fragment>
    </>
  );
});

export default HANWHA_COM_SECURITY_DECLARATION;
